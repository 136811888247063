import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MainScreen from "../../components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/custom/loading";
import ErrorMessage from "../../components/custom/errorMessage";
import { getUserInfo, updateUserInfo, updatePassword } from "../../redux/actions/users";
import moment from "moment";

const MyProfile = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [message, setMessage] = useState(null);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { loading, error, success, userInfo, passwordInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const submitHandler = async (event) => {
    setMessage(null);
    event.preventDefault();
    dispatch(updateUserInfo(userInfo._id, username, email));
    if (success) setMessage("Perfil actualizado");
  };

  const updatePasswordHandler = async (event) => {
    setMessage(null);
    event.preventDefault();
    dispatch(updatePassword(currentPassword, newPassword, newPasswordConfirm));
    if (passwordInfo.message) setMessage("Contraseña actualizada");
  };

  useEffect(() => {
    setTimeout(() => {
      if (!userInfo) window.location.href = "/";
      else {
        setUsername(userInfo.username);
        setEmail(userInfo.email);
        setOrganisationName(organisationInfo.name);
      }
    }, 300);
  }, [userInfo, success]);

  if (loading) {
    return (
      <MainScreen title="Mi Perfil">
        <div className="container mt-5 myProfile">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Mi Perfil">
        <div className="shadow p-3 mb-3 bg-white rounded">
          <Form onSubmit={submitHandler}>
            {/* General Info Card */}
            <Card className="mb-3">
              <Card.Header>Datos</Card.Header>
              <Card.Body>
                <Row className="align-items-center">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Organización</Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          value={organisationName}
                          disabled
                          onChange={(event) => setOrganisationName(event.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  {organisationName && userInfo.role === "owner" && (
                    <Col>
                      <Button
                        variant="outline-primary"
                        className="mt-2"
                        onClick={() => (window.location.href = "/myorganisation")}
                      >
                        Ver Organización
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  {/* Username */}
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Nombre de Usuario</Form.Label>
                      <Form.Control
                        type="text"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  {/* Email */}
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Submit */}
                <Button variant="primary" type="submit" className="">
                  Guardar Cambios
                </Button>
              </Card.Body>
            </Card>
          </Form>
          <Form onSubmit={updatePasswordHandler}>
            {/* General Info Card */}
            <Card className="mb-3">
              <Card.Header>Modificar Contraseña</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Contraseña Actual</Form.Label>
                      <Form.Control
                        type="password"
                        value={currentPassword}
                        onChange={(event) => setCurrentPassword(event.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Nueva Contraseña</Form.Label>
                      <Form.Control
                        type="password"
                        value={newPassword}
                        onChange={(event) => setNewPassword(event.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirmar Nueva Contraseña</Form.Label>
                      <Form.Control
                        type="password"
                        value={newPasswordConfirm}
                        onChange={(event) => setNewPasswordConfirm(event.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Submit */}
                <Button variant="primary" type="submit" className="">
                  Modificar Contraseña
                </Button>
              </Card.Body>
            </Card>
          </Form>
        </div>
        {message && <ErrorMessage variant="success">{message}</ErrorMessage>}
        {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
      </MainScreen>
    );
  }
};

export default MyProfile;
