import {
  DASHBOARD_LIST_REQUEST,
  DASHBOARD_LIST_SUCCESS,
  DASHBOARD_LIST_FAILURE,
} from "../constants/dashboard";

export const dashboardReducer = (state = { dashboard: [] }, action) => {
  switch (action.type) {
    case DASHBOARD_LIST_REQUEST:
      return { loading: true };
    case DASHBOARD_LIST_SUCCESS:
      return { loading: false, dashboard: action.payload };
    case DASHBOARD_LIST_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
