import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Col, Row, Card, Table } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { updateMemberAction, getMemberById, listMembers } from "redux/actions/members";
import axios from "axios";

import UserCard from "components/userCard";

export default () => {
  const [membershipId, setMembershipId] = useState("");
  const [nickname, setNickname] = useState("");
  const [referrer, setReferrer] = useState({
    id: "",
    label: "",
  });
  const [consumerType, setConsumerType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [dni, setDni] = useState("");
  const [dniFront, setDniFront] = useState("");
  const [dniBack, setDniBack] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [membershipDate, setMembershipDate] = useState("");
  const [monthlyConsumptionForecast, setMonthlyConsumptionForecast] = useState(0);
  const [date, setDate] = useState("");
  const [image, setImage] = useState("");
  const [contract, setContract] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const membersReducer = useSelector((state) => state.members);
  const { members, member, loading, error, success } = membersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    console.log("reset");
    setMembershipId("");
    setNickname("");
    setReferrer("");
    setConsumerType("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCity("");
    setGender("");
    setDni("");
    setDniFront("");
    setDniBack("");
    setBirthDate("");
    setMembershipDate("");
    setMonthlyConsumptionForecast(0);
    setImage("");
    setContract("");

    window.location.href = "/members";
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    dispatch(
      updateMemberAction(
        id,
        membershipId,
        nickname,
        referrer.id,
        consumerType,
        firstName,
        lastName,
        email,
        phone,
        address,
        city,
        gender,
        dni,
        dniFront,
        dniBack,
        birthDate,
        membershipDate,
        monthlyConsumptionForecast,
        image,
        contract
      )
    );
  };

  useEffect(() => {
    if (!userInfo) window.location.href = "/";
    dispatch(listMembers());
    dispatch(getMemberById(id));
    if (success) resetHandler();
  }, [userInfo]);

  useEffect(() => {
    if (member) {
      setDate(member.date);
      setMembershipId(member.membershipId);
      setNickname(member.nickname);
      setConsumerType(member.consumerType);
      setFirstName(member.firstName);
      setLastName(member.lastName);
      setEmail(member.email);
      setPhone(member.phone);
      setAddress(member.address);
      setCity(member.city);
      setGender(member.gender);
      setDni(member.dni);
      setDniFront(member.dniFront);
      setDniBack(member.dniBack);
      setBirthDate(new Date(member.birthDate).toISOString().substring(0, 10));
      setMembershipDate(new Date(member.membershipDate).toISOString().substring(0, 10));
      setMonthlyConsumptionForecast(member.monthlyConsumptionForecast);
      setImage(member.image);
      setContract(member.contract);
    }
  }, [member]);

  useEffect(() => {
    if (members && member) {
      members.map((m) => {
        if (m.membershipId === member.referrer) {
          console.log(m);
          setReferrer({
            id: m.membershipId,
            label: m.membershipId + " - " + m.firstName + " " + m.lastName,
          });
        }
      });
    }
  }, [members, member]);

  if (loading) {
    return (
      <MainScreen title="Editar Socio">
        <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Editar Socio">
        <form onSubmit={submitHandler} className="p-0">
          <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
            <UserCard
              members={members}
              membershipId={membershipId}
              nickname={nickname}
              referrer={referrer}
              consumerType={consumerType}
              firstName={firstName}
              lastName={lastName}
              email={email}
              phone={phone}
              address={address}
              city={city}
              membershipDate={membershipDate}
              monthlyConsumptionForecast={monthlyConsumptionForecast}
              dniFront={dniFront}
              dniBack={dniBack}
              birthDate={birthDate}
              gender={gender}
              dni={dni}
              image={image}
              contract={contract}
              type="edit"
              setMembershipDate={setMembershipDate}
              setMonthlyConsumptionForecast={setMonthlyConsumptionForecast}
              setDniFront={setDniFront}
              setDniBack={setDniBack}
              setBirthDate={setBirthDate}
              setGender={setGender}
              setDni={setDni}
              setMembershipId={setMembershipId}
              setNickname={setNickname}
              setReferrer={setReferrer}
              setConsumerType={setConsumerType}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setPhone={setPhone}
              setAddress={setAddress}
              setCity={setCity}
              setImage={setImage}
              setContract={setContract}
            />

            {/* Update */}
            <Row>
              <Col md={12}>
                <Button
                  variant="primary"
                  type="submit"
                  /* expand button */
                  style={{ width: "100%" }}
                >
                  Actualizar
                </Button>
              </Col>
            </Row>
          </div>
          {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
          {success && <ErrorMessage variant="success">Member Updated Successfully</ErrorMessage>}
        </form>
      </MainScreen>
    );
  }
};
