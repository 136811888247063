import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import MemberSelect from "components/memberSelect";

import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { getEntryById } from "redux/actions/entries";
import { listMembers } from "redux/actions/members";
import axios from "axios";

export default () => {
  const [member, setMember] = useState({
    id: "",
    label: "",
  });
  const [entryDate, setEntryDate] = useState("");
  const [date, setDate] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const entriesReducer = useSelector((state) => state.entries);
  const { entries, loading, error, success } = entriesReducer;

  const membersReducer = useSelector((state) => state.members);
  const { members } = membersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    setMember([]);
    setEntryDate("");
    setDate("");

    window.location.href = "/entries";
  };

  const fetchData = async () => {
    setMember({
      id: entries.entry.memberId.membershipId,
      label:
        entries.entry.memberId.membershipId +
        " - " +
        entries.entry.memberId.firstName +
        " " +
        entries.entry.memberId.lastName,
    });
    setEntryDate(new Date(entries.entry.entryDate).toISOString().substring(0, 16));
    setDate(entries.entry.date);
  };

  useEffect(() => {
    if (!userInfo) window.location.href = "/";
    dispatch(getEntryById(id));
    dispatch(listMembers());
    if (success) resetHandler();
  }, [userInfo, success, dispatch]);

  useEffect(() => {
    async function fetch() {
      if (entries && entries.entry && entries.entry.memberId) {
        await fetchData();
      }
    }
    fetch();
  }, [entries]);

  if (loading) {
    return (
      <MainScreen title="Visualizar Entrada">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Visualizar Entrada">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <p className="my-3 text-muted">
            Fecha Edición - {new Date().toLocaleDateString("es-ES")}
          </p>
          <Form>
            <Form.Group className="mb-3" controlId="form-memberId">
              <Form.Label>Id Socio</Form.Label>
              {member ? (
                <Form.Control type="text" value={member.label} disabled />
              ) : (
                <Form.Control type="text" value="No hay socios" disabled />
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="form-entryDate">
              <Form.Label>Fecha / Hora Entrada</Form.Label>
              <Form.Control
                type="datetime-local"
                placeholder="Fecha / Hora Entrada"
                disabled
                value={entryDate}
                required
              />
            </Form.Group>

            {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
            {success && <ErrorMessage variant="success">Entry Created Successfully</ErrorMessage>}
          </Form>
        </div>
      </MainScreen>
    );
  }
};
