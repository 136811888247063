import React, { useEffect, useState } from "react";
import { Card, Button, Container } from "react-bootstrap";

import { listener, startTablet } from "../../utils/SigWebTablet";
/* sdk
   https://www.topazsystems.com/web.html
   Dev Guide
   https://www.topazsystems.com/Software/download/sigweb.pdf
   installer
   https://www.topazsystems.com/software/sigweb.exe
*/

function TopazSignature() {
  const [sigCaptured, setSigCaptured] = useState(null);

  listener();

  const handleSig = () => {};

  const handleSigCapture = (sig) => {
    let image = new Image();
    image.src = "data:image/png;base64," + sig;
    setSigCaptured({ image, sig });
  };

  return (
    <Card align="center">
      <Card.Body>
        <form action="#" name="FORM1">
          {!sigCaptured ? (
            <Button
              onClick={() => {
                startTablet(handleSigCapture);
              }}
            >
              Firmar
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleSig();
              }}
            >
              Completar
            </Button>
          )}
          Please have customer sign signature pad.
          <Container p={2}>
            SigString:
            <Card name="sigString" rows="20" multiline />
          </Container>
          <Container p={2}>
            ImgData:
            <Card
              name="imgData"
              rows="20"
              multiline
              value={sigCaptured?.image.src}
            />
          </Container>
          <Container p={2}>
            Image:
            <img src={sigCaptured?.image.src} />
          </Container>
        </form>
      </Card.Body>
    </Card>
  );
}

export default TopazSignature;
