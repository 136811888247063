import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { getPurchaseById } from "redux/actions/purchases";
import TopazSignature from "components/custom/topazSignature";
import moment from "moment";
import axios from "axios";

import TxTable from "components/txTable";
import { PdfInput } from "components/imageInput";

import { addNewProduct, resetHandler, editHandler, removeProduct } from "../helpers/functions";

import { listProducts } from "redux/actions/products";
import { listSuppliers } from "redux/actions/suppliers";

const openDni = (e, b64) => {
  let pdfWindow = window.open("", "_blank");
  pdfWindow.document.write(`<iframe width='100%' height='100%' src=${encodeURI(b64)}></iframe>`);
};

export default () => {
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [date, setDate] = useState("");
  const [supplier, setSupplier] = useState("");

  const [productsArray, setProductsArray] = useState([]);
  const [bill, setBill] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  /* Redux State */
  const productsReducer = useSelector((state) => state.products);
  const { products } = productsReducer;

  const purchasesReducer = useSelector((state) => state.purchases);
  const { purchases, loading, error, success } = purchasesReducer;

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { suppliers } = suppliersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const fetchData = async () => {
    setProductsArray(purchases.purchase.products);
    setDate(new Date(purchases.purchase.date).toISOString().slice(0, 16));
    setTotal(purchases.purchase.total_cost);
    setTotalWeight(purchases.purchase.total_weight);
    setBill(purchases.purchase.bill);
    setSupplier(
      suppliers.suppliers.find((supplier) => supplier._id === purchases.purchase.supplier._id)
    );
  };

  /* useEffect */
  useEffect(() => {
    dispatch(getPurchaseById(id));
    dispatch(listProducts());
    dispatch(listSuppliers());

    if (!userInfo) window.location.href = "/";
    if (success) resetHandler(setProductsArray, setTotal, setTotalWeight, setDate);
    setDate(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
  }, [userInfo, success]);

  useEffect(() => {
    if (purchases && purchases.purchase) fetchData();
  }, [purchases]);

  if (loading) {
    return (
      <MainScreen title="Ver Compra">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Ver Compra">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <p className="my-3 text-muted">
            Fecha Edición - {new Date().toLocaleDateString("es-ES")}
          </p>
          <Form>
            <Row>
              {/* Fecha */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-date">
                  <Form.Label>Fecha / Hora Compra</Form.Label>
                  <Form.Control type="datetime-local" disabled value={date} required />
                </Form.Group>
              </Col>
              {/* Proveedor */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-supplier">
                  <Form.Label>Proveedor</Form.Label>
                  <Form.Control type="text" value={supplier.name} disabled />
                </Form.Group>
              </Col>
              <Row>
                <Col md={3}>
                  <Form.Label>Factura</Form.Label>
                  <td>
                    {bill ? (
                      <a
                        href={bill}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          openDni(e, bill);
                        }}
                      >
                        Ver
                      </a>
                    ) : (
                      "No disponible"
                    )}
                  </td>
                </Col>
                <Col md={6}></Col>
              </Row>
            </Row>
            <p className="my-3 text-muted">Productos:</p>
            {productsArray && products && (
              <TxTable
                productsArray={productsArray}
                setProductsArray={setProductsArray}
                products={products}
                type="read"
                screen="purchase"
              />
            )}
          </Form>
        </div>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      </MainScreen>
    );
  }
};
