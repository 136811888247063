import React from "react";
import { Card, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./infoCard.css";

const InfoCard = (props) => {
  const { icon, title, value, colSize, sufix, link } = props;
  return (
    <Col md={colSize} className="">
      <Card
        className="shadow mb-3 bg-white rounded d-flex"
        onClick={() => {
          if (link) {
            window.location.href = link;
          }
        }}
        style={link && { cursor: "pointer" }}
      >
        <Card.Header className="d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={icon} size="2x" color="lightblue" className="mx-2" />
          <h5 className="mb-0">{title}</h5>
        </Card.Header>
        <Card.Body>
          <h5 className="text-center m-auto">
            {Math.round(value * 100) / 100 + " " + (sufix ? sufix : "")}
          </h5>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default InfoCard;
