import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { createPurchaseAction } from "redux/actions/purchases";
import { PdfInput } from "components/imageInput";
import moment from "moment";

import TxTable from "components/txTable";

import { addNewProduct, resetHandler, removeProduct } from "../helpers/functions";

import { listProducts } from "redux/actions/products";
import { listMembers } from "redux/actions/members";
import { listSuppliers } from "redux/actions/suppliers";

export default () => {
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
  const [supplier, setSupplier] = useState("");
  const [productsArray, setProductsArray] = useState([]);
  const [productsBySupplier, setProductsBySupplier] = useState([]);
  const [bill, setBill] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "RESET_PURCHASES" });
  }, [dispatch]);

  /* Redux State */
  const productsReducer = useSelector((state) => state.products);
  const { products } = productsReducer;

  const purchasesReducer = useSelector((state) => state.purchases);
  const { loading, error, success } = purchasesReducer;

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { suppliers } = suppliersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const productId = searchParams.get("productId");
  const supplierId = searchParams.get("supplierId");

  /* useEffect */
  useEffect(() => {
    dispatch(listProducts());
    dispatch(listSuppliers());
    if (!userInfo) window.location.href = "/";
    if (success) resetHandler(setProductsArray, setTotal, setDate, setTotalWeight);
    setDate(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
  }, [userInfo, success]);

  useEffect(() => {
    if (products && products.products && productId) {
      setProductsArray([
        {
          _id: productId,
          quantity: 1,
          stamps: {
            price: products.products.find((product) => product._id === productId).price,
            weight: products.products.find((product) => product._id === productId).weight,
          },
        },
      ]);
    }
    suppliers &&
      suppliers.suppliers &&
      supplierId &&
      setSupplier(suppliers.suppliers.find((supplier) => supplier._id === supplierId));
  }, [products, productId, supplierId, suppliers]);

  if (loading) {
    return (
      <MainScreen title="Registrar Compra">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Registrar Compra">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <p className="my-3 text-muted">
            Fecha Creación - {new Date().toLocaleDateString("es-ES")}
          </p>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (
                productsArray.reduce((acc, item) => acc + item.quantity * item.stamps.price, 0) <
                supplier.minPurchase
              )
                alert(
                  "El monto de la compra no puede ser menor al monto mínimo de compra del proveedor: " +
                    supplier.minPurchase
                );
              else dispatch(createPurchaseAction(productsArray, date, supplier._id, bill));
            }}
          >
            <Row>
              {/* Fecha */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-date">
                  <Form.Label>Fecha / Hora Compra</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-date">
                  <Form.Label>Proveedor</Form.Label>
                  {suppliers && suppliers.suppliers && suppliers.suppliers.length > 0 ? (
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) => {
                        setSupplier(
                          suppliers.suppliers.find((supplier) => supplier._id === e.target.value)
                        );
                      }}
                      value={supplier ? supplier._id : ""}
                    >
                      <option value="">Seleccionar Proveedor</option>
                      {suppliers &&
                        suppliers.suppliers &&
                        suppliers.suppliers.map((supplier) => (
                          <option key={supplier._id} value={supplier._id}>
                            {supplier.name}
                          </option>
                        ))}
                    </Form.Control>
                  ) : (
                    <Form.Control type="text" placeholder="No hay Proveedores" disabled />
                  )}
                </Form.Group>
              </Col>
              <Row>
                <Col md={3}>
                  <Form.Label>Factura</Form.Label>
                  <PdfInput value={bill} setValue={setBill} customRef="billId" />
                </Col>
                <Col md={6}></Col>
              </Row>
            </Row>
            <p className="my-3 text-muted">Productos:</p>
            {/* Generar Nuevo Producto Button */}
            <TxTable
              productsArray={productsArray}
              setProductsArray={setProductsArray}
              products={products}
              productsAvailable={supplier ? supplier.products : []}
              type="create"
              screen="purchase"
            />
            <Row>
              <Col md={6}>
                <Button variant="primary" type="submit" className="m-1">
                  Crear
                </Button>
              </Col>
            </Row>
            {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
            {success && (
              <ErrorMessage variant="success">Purchase Created Successfully</ErrorMessage>
            )}
          </Form>
        </div>
      </MainScreen>
    );
  }
};
