import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import MainScreen from "../../components/layout/mainScreen/mainScreen";
import Loading from "../../components/custom/loading";
import ErrorMessage from "../../components/custom/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/users";

const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { loading, error, userInfo } = user;

  useEffect(() => {
    if (userInfo) {
      window.location.href = "/dashboard";
      console.log(userInfo);
    }
  }, [userInfo]);

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(login(username, password));
  };

  if (loading) {
    return (
      <MainScreen title="Login">
        <div className="container mt-5 loginContainer">
          <Loading />
        </div>
      </MainScreen>
    );
  } else if (!loading) {
    return (
      <MainScreen title="Login">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formBasicUsername">
              <Form.Label>Usuario</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre de usuario"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Form.Group>
            {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
            <Button variant="primary" className="px-4" type="submit">
              Enviar
            </Button>
          </Form>
        </div>

        <Row className="py-3">
          <Col>
            ¿No tienes una cuenta?{" "}
            <a href="/register" className="text-decoration-none">
              Regístrate
            </a>
          </Col>
        </Row>

        <Row className="py-3">
          <Col>
            ¿Olvidaste tu contraseña?{" "}
            <a href="/forgotpassword" className="text-decoration-none">
              Recupérala
            </a>
          </Col>
        </Row>
      </MainScreen>
    );
  }
};

export default LoginScreen;
