import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listPurchases, deletePurchaseAction } from "redux/actions/purchases";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid, StripedDataGridWithFooter } from "components/custom/stripedDataGrid.js";

const currencyFormatter = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

/* Format value grams */
const gramsFormatter = new Intl.NumberFormat("es-ES", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export default () => {
  const dispatch = useDispatch();

  const purchasesReducer = useSelector((state) => state.purchases);
  const { loading, error, purchases, success } = purchasesReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const customButton = () => {
    if (organisationInfo) {
      return (
        <Link to="/purchases/create">
          <Button className="m-2" variant="success">
            Nueva Compra
          </Button>
        </Link>
      );
    } else {
      return (
        <Link to="/organisation/create">
          <Button className="btn btn-sm m-2" variant="success">
            Nueva Organización
          </Button>
        </Link>
      );
    }
  };

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deletePurchaseAction(id));
      window.location.href = "/purchases";
    }
  };

  const defineDataGrid = (purchases) => {
    let rows = [];

    purchases.purchases.forEach((purchase) => {
      purchase.products.map((product) => {
        console.log(product.stamps);
      });
      const discount = (
        100 -
        (purchase.products
          .map(
            (product) =>
              product.quantity * product.stamps.price -
              product.quantity * product.stamps.price * (product.discount / 100)
          )
          .reduce((a, b) => a + b) /
          purchase.products
            .map((product) => product.quantity * product.stamps.price)
            .reduce((a, b) => a + b)) *
          100
      ).toFixed(2);

      rows.push({
        id: purchase._id,
        supplier: purchase.supplier && purchase.supplier.name,
        total: purchase.products
          .map((product) => product.stamps.price * product.quantity)
          .reduce((a, b) => a + b, 0),
        weight: purchase.products
          .map((product) => product.stamps.weight * product.quantity)
          .reduce((a, b) => a + b, 0),
        products: purchase.products.length,
        discount: discount > 0 ? discount + " %" : "0 %",
        date: purchase.date,
        bill: purchase.bill,
      });
    });

    setRowsDefs(rows);
    setColumnDefs([
      /* Fecha y hora */
      {
        headerName: "Fecha y hora",
        field: "date",
        width: 200,
        sortable: true,
        filter: true,
        type: "dateTime",
        renderCell: (params) => {
          return (
            <div>
              {new Date(params.value)
                .toISOString()
                .slice(0, 19)
                .replace(/-/g, "/")
                .replace("T", " ")}
            </div>
          );
        },
      },
      /* ID */
      {
        headerName: "Id",
        field: "id",
        width: 100,
        sortable: true,
        filter: true,
      },
      /* Proveedor */
      {
        headerName: "Proveedor",
        field: "supplier",
        width: 150,
        sortable: true,
        filter: true,
      },
      /* Productos */
      {
        headerName: "Productos",
        field: "products",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      {
        headerName: "Descuento",
        field: "discount",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      /* Total €*/
      {
        headerName: "Total €",
        field: "total",
        width: 150,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: (params) => {
          return currencyFormatter.format(params.value);
        },
      },
      /* Total W*/
      {
        headerName: "Peso Total",
        field: "weight",
        width: 150,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: (params) => {
          return gramsFormatter.format(params.value) + " g";
        },
      },
      {
        headerName: "Factura",
        field: "contract",
        width: 120,

        renderCell: (rowData) => {
          return rowData.row.bill ? (
            <a href={rowData.row.bill} target="_blank">
              <Button className="btn btn-sm m-2" variant="success" disabled>
                Disponible
              </Button>
            </a>
          ) : (
            <Button className="btn btn-sm m-2" variant="danger" disabled>
              No Disponible
            </Button>
          );
        },
      },

      /* Acciones */
      {
        headerName: "Acciones",
        field: "actions",
        width: 200,
        renderCell: true
          ? (rowData) => {
              return (
                <div>
                  {userInfo &&
                    userInfo.permissions &&
                    userInfo.permissions["members"]["update"] && (
                      <Link to={`/purchases/${rowData.id}`}>
                        <Button className="btn btn-sm m-2" variant="success">
                          Ver
                        </Button>
                      </Link>
                    )}
                  {/* {userInfo && userInfo.permissions && userInfo.permissions["members"]["delete"] && (
                    <Link to={`/purchases/edit/${rowData.id}`}>
                      <Button className="btn btn-sm m-2" variant="warning">
                        Editar
                      </Button>
                    </Link>
                  )} */}
                  <Button
                    variant="danger"
                    onClick={() => deleteHandler(rowData.id)}
                    className=" btn btn-sm m-2"
                  >
                    Eliminar
                  </Button>
                </div>
              );
            }
          : false,
      },
    ]);
  };

  useEffect(() => {
    dispatch(listPurchases());
    if (!userInfo) window.location.href = "/login";
  }, [dispatch, success, userInfo]);

  useEffect(() => {
    if (purchases && purchases.purchases) {
      defineDataGrid(purchases);
    }
  }, [purchases]);

  return (
    <MainScreen
      title={`Compras`}
      button={
        userInfo &&
        userInfo.permissions &&
        userInfo.permissions["members"]["create"] &&
        customButton()
      }
    >
      <Row>
        {purchases && purchases.purchases && (
          <div>
            <StripedDataGridWithFooter
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              sortModel={[{ field: "date", sort: "desc" }]}
            />
          </div>
        )}
      </Row>
      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      {loading && <Loading />}
    </MainScreen>
  );
};
