import {
  DASHBOARD_LIST_REQUEST,
  DASHBOARD_LIST_SUCCESS,
  DASHBOARD_LIST_FAILURE,
} from "../constants/dashboard";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listDashboard = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_LIST_REQUEST });

    const { data } = await axios.get("/api/dashboard", apiConfig);

    dispatch({ type: DASHBOARD_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: DASHBOARD_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
