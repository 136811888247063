import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row, Card, Table } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { createSupplierAction } from "redux/actions/suppliers";
import { getCardHeaderUtilityClass } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import ProductSelect from "components/productSelect/supplierProductSelect";

import { listProducts } from "redux/actions/products";

import SupplierCard from "components/supplierCard";

export default () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [minPurchase, setMinPurchase] = useState("");
  const [productsArray, setProductsArray] = useState([]);
  const [image, setImage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SUPPLIER_RESET" });
  }, [dispatch]);

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { loading, error, success } = suppliersReducer;

  const productsReducer = useSelector((state) => state.products);
  const { products } = productsReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCity("");
    setMinPurchase("");
    setProductsArray([]);
    setImage("");

    window.location.href = "/suppliers";
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(
      createSupplierAction(name, email, phone, address, city, productsArray, minPurchase, image)
    );
  };

  function removeProduct(index, setProductsArray, productsArray) {
    let newArray = [...productsArray];
    newArray.splice(index, 1);
    setProductsArray(newArray);
  }

  function addNewProduct(setProductsArray, productsArray) {
    setProductsArray([
      ...productsArray,
      {
        _id: "",
        price: "",
      },
    ]);
  }

  useEffect(() => {
    if (!userInfo) window.location.href = "/";
    if (success) resetHandler();
    dispatch(listProducts());
  }, [userInfo, success]);

  if (loading) {
    return (
      <MainScreen title="Crear Proveedor">
        <div className="container mt-5 createSupplier">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Crear Proveedor">
        <form onSubmit={submitHandler} className="p-0">
          <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
            <SupplierCard
              name={name}
              email={email}
              phone={phone}
              address={address}
              city={city}
              image={image}
              minPurchase={minPurchase}
              type="create"
              setName={setName}
              setEmail={setEmail}
              setPhone={setPhone}
              setAddress={setAddress}
              setCity={setCity}
              setMinPurchase={setMinPurchase}
              setImage={setImage}
            />
            <Row>
              <Col md={12}>
                <Button
                  variant="success"
                  onClick={() => addNewProduct(setProductsArray, productsArray)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Table striped="columns">
                  <thead>
                    <tr>
                      <th>Acciones</th>
                      <th>Producto</th>
                      <th>Descripción</th>
                      <th>Peso</th>
                      <th>Precio Venta</th>
                      <th>Precio Compra</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Products */}
                    {productsArray.map((product, index) => {
                      return (
                        <tr key={index} className="align-middle">
                          <td>
                            <Button
                              variant="danger"
                              className="btn-sm"
                              onClick={() => removeProduct(index, setProductsArray, productsArray)}
                            >
                              <FontAwesomeIcon icon={faTrash} style={{ color: "white" }} /> Borrar
                            </Button>
                          </td>
                          <td className="text-start">
                            {products &&
                              products.products &&
                              product &&
                              (console.log(product),
                              (
                                <ProductSelect
                                  products={products}
                                  product={product}
                                  productsArray={productsArray}
                                  setProductsArray={setProductsArray}
                                  index={index}
                                />
                              ))}
                          </td>
                          <td>
                            <input
                              type="text"
                              value={
                                products &&
                                products.products &&
                                product &&
                                product._id &&
                                products.products.find((p) => p._id === product._id).description
                              }
                              disabled
                              className="customInput"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={
                                products &&
                                products.products &&
                                product &&
                                product._id &&
                                products.products.find((p) => p._id === product._id).weight
                              }
                              disabled
                              className="customInput"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={
                                products &&
                                products.products &&
                                product &&
                                product._id &&
                                products.products.find((p) => p._id === product._id).price
                              }
                              disabled
                              className="customInput"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={product.price}
                              onChange={(e) => {
                                let newArray = [...productsArray];
                                newArray[index].price = e.target.value;
                                setProductsArray(newArray);
                              }}
                              className="customInput"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {/* Update */}

            <Row className="mt-5">
              <Col md={12}>
                <Button
                  variant="primary"
                  type="submit"
                  /* expand button */
                  style={{ width: "100%" }}
                >
                  Crear
                </Button>
              </Col>
            </Row>
          </div>
          {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
          {success && <ErrorMessage variant="success">Supplier Updated Successfully</ErrorMessage>}
        </form>
      </MainScreen>
    );
  }
};
