import React from "react";
import { useState } from "react";
import { Card, Col, Button, ButtonGroup } from "react-bootstrap";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const LineChartComponent = (props) => {
  const { data, infoFilter } = props;
  console.log(data);
  return (
    <Col>
      <Card className="shadow mb-3 bg-white rounded d-flex">
        <Card.Body>
          <ResponsiveContainer width="100%" height={300} className="">
            <LineChart data={data}>
              <Line type="monotone" dataKey={infoFilter} stroke="#8884d8" />

              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default LineChartComponent;
