import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { userReducer } from "./reducers/users";
import { membersReducer } from "./reducers/members";
import { dashboardReducer } from "./reducers/dashboard";
import { entriesReducer } from "./reducers/entries";
import { productsReducer } from "./reducers/products";
import { transactionsReducer } from "./reducers/transactions";
import { purchasesReducer } from "./reducers/purchases";
import { organisationReducer } from "./reducers/organisations";
import { suppliersReducer } from "./reducers/suppliers";

const reducer = combineReducers({
  user: userReducer,
  organisation: organisationReducer,
  members: membersReducer,
  entries: entriesReducer,
  products: productsReducer,
  transactions: transactionsReducer,
  purchases: purchasesReducer,
  suppliers: suppliersReducer,
  dashboard: dashboardReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const organisationInfoFromStorage = localStorage.getItem("organisationInfo")
  ? JSON.parse(localStorage.getItem("organisationInfo"))
  : null;

const initialState = {
  user: { userInfo: userInfoFromStorage },
  organisation: { organisationInfo: organisationInfoFromStorage },
};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
