import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { updateTransactionAction, getTransactionById } from "redux/actions/transactions";
import TopazSignature from "components/custom/topazSignature";
import moment from "moment";
import axios from "axios";

import {
  handleSig,
  handleSigCapture,
  addNewProduct,
  drawSignature,
  resetHandler,
  removeProduct,
} from "../helpers/functions";

import { listener, startTablet } from "utils/SigWebTablet";

import { listProducts } from "redux/actions/products";
import { listMembers } from "redux/actions/members";
import TxTable from "components/txTable";
import MemberSelect from "components/memberSelect";

export default () => {
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [member, setMember] = useState({
    _id: "",
    label: "",
  });
  const [signature, setSignature] = useState("");
  const [date, setDate] = useState("");
  const [sigCaptured, setSigCaptured] = useState(null);
  const [productsArray, setProductsArray] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();
  const dispatch = useDispatch();

  /* TOPAZ */
  listener();

  /* Redux State */
  const productsReducer = useSelector((state) => state.products);
  const { products } = productsReducer;

  const membersReducer = useSelector((state) => state.members);
  const { members } = membersReducer;

  const transactionsReduceer = useSelector((state) => state.transactions);
  const { transactions, loading, error, success } = transactionsReduceer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const fetchData = async () => {
    setProductsArray(transactions.transaction.products);
    setMember({
      id: transactions.transaction.memberId.membershipId,
      label:
        transactions.transaction.memberId.membershipId +
        " - " +
        transactions.transaction.memberId.firstName +
        " " +
        transactions.transaction.memberId.lastName,
    });
    setSignature(transactions.transaction.signature);
    setDate(new Date(transactions.transaction.date).toISOString().slice(0, 16));
    setSignature(transactions.transaction.signature);
    setTotal(transactions.transaction.total_cost);
    setTotalWeight(transactions.transaction.total_weight);

    setTimeout(() => {
      drawSignature(transactions.transaction.signature);
    }, 1000);
  };

  /* useEffect */
  useEffect(() => {
    dispatch(getTransactionById(id));
    dispatch(listProducts());
    dispatch(listMembers());

    if (!userInfo) window.location.href = "/";

    if (success)
      resetHandler(setProductsArray, setTotal, setTotalWeight, setMember, setSignature, setDate);
    setDate(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
  }, [userInfo, success, dispatch]);

  useEffect(() => {
    async function fetch() {
      await fetchData();
    }
    if (transactions && transactions.transaction) fetch();
  }, [transactions]);

  if (loading) {
    return (
      <MainScreen title="Editar Transacción">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Editar Transacción">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <p className="my-3 text-muted">
            Fecha Edición - {new Date().toLocaleDateString("es-ES")}
          </p>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(updateTransactionAction(id, member.id, productsArray, signature, date));
            }}
          >
            <Row>
              {/* Socio */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-member">
                  <Form.Label>Socio</Form.Label>
                  {members ? (
                    <MemberSelect members={members} member={member} setMember={setMember} />
                  ) : (
                    <Form.Control type="text" placeholder="No hay socios" disabled />
                  )}
                </Form.Group>
              </Col>
              {/* Fecha */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-date">
                  <Form.Label>Fecha / Hora Transacción</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <p className="my-3 text-muted">Productos:</p>
            <TxTable
              productsArray={productsArray}
              setProductsArray={setProductsArray}
              products={products}
              type="update"
              screen="transaction"
            />

            <p className="my-3 text-muted">Firma :</p>
            <Row>
              <Col md={12} className="border m-3">
                <canvas id="cnv" width="500" height="100"></canvas>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  className="mr-1"
                  variant="warning"
                  onClick={() => {
                    startTablet();
                  }}
                >
                  Firmar
                </Button>
                <Button
                  variant="success"
                  className="m-1"
                  onClick={() => {
                    handleSigCapture(setSigCaptured, setSignature);
                  }}
                >
                  Completar
                </Button>
                {signature.length > 0 && (
                  <Button variant="primary" type="submit" className="m-1">
                    Actualizar
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {success && <ErrorMessage variant="success">Transaction Update Successfully</ErrorMessage>}
      </MainScreen>
    );
  }
};
