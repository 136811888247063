import {
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_LIST_FAILURE,
  PURCHASE_CREATE_REQUEST,
  PURCHASE_CREATE_SUCCESS,
  PURCHASE_CREATE_FAILURE,
  PURCHASE_UPDATE_REQUEST,
  PURCHASE_UPDATE_SUCCESS,
  PURCHASE_UPDATE_FAILURE,
  PURCHASE_DELETE_REQUEST,
  PURCHASE_DELETE_SUCCESS,
  PURCHASE_DELETE_FAILURE,
  RESET_PURCHASES,
} from "../constants/purchases";

export const purchasesReducer = (state = { purchases: [] }, action) => {
  switch (action.type) {
    case PURCHASE_LIST_REQUEST:
      return { loading: true };
    case PURCHASE_LIST_SUCCESS:
      return { loading: false, purchases: action.payload };
    case PURCHASE_LIST_FAILURE:
      return { loading: false, error: action.payload };
    case PURCHASE_CREATE_REQUEST:
      return { loading: true };
    case PURCHASE_CREATE_SUCCESS:
      return { loading: false, success: true };
    case PURCHASE_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case PURCHASE_UPDATE_REQUEST:
      return { loading: true };
    case PURCHASE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PURCHASE_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case PURCHASE_DELETE_REQUEST:
      return { loading: true };
    case PURCHASE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PURCHASE_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case RESET_PURCHASES:
      return { purchases: [] };
    default:
      return state;
  }
};
