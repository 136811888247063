import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listTransactions, deleteTransactionAction } from "redux/actions/transactions";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid, StripedDataGridWithFooter } from "components/custom/stripedDataGrid.js";

const currencyFormatter = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

/* Format value grams */
const gramsFormatter = new Intl.NumberFormat("es-ES", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export default () => {
  const dispatch = useDispatch();

  const transactionsReduceer = useSelector((state) => state.transactions);
  const { loading, error, transactions, success } = transactionsReduceer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteTransactionAction(id));
      window.location.href = "/transactions";
    }
  };

  const defineDataGrid = (transactions) => {
    let rows = [];

    transactions.transactions.forEach((transaction) => {
      console.log(transaction);
      const discount = (
        100 -
        (transaction.products
          .map(
            (product) =>
              product.quantity * product.stamps.price -
              product.quantity * product.stamps.price * (product.discount / 100)
          )
          .reduce((a, b) => a + b) /
          transaction.products
            .map((product) => product.quantity * product.stamps.price)
            .reduce((a, b) => a + b)) *
          100
      ).toFixed(2);

      rows.push({
        id: transaction._id,
        member: transaction.memberId
          ? transaction.memberId.membershipId
          : "Unknown / Deleted Account",
        total: transaction.products
          .map((product) => {
            const initialPrice = product.quantity * product.stamps.price;
            const discountPrice = initialPrice * (product?.discount / 100);
            return initialPrice - discountPrice;
          })
          .reduce((a, b) => a + b),
        weight: transaction.products
          .map((product) => product.stamps.weight * product.quantity)
          .reduce((a, b) => a + b, 0),
        products: transaction.products.length,
        discount: discount > 0 ? discount + " %" : "0 %",
        signature: transaction.signature,
        date: transaction.date,
        user: transaction.user,
      });
    });

    setRowsDefs(rows);
    setColumnDefs([
      /* Fecha y hora */
      {
        headerName: "Fecha y hora",
        field: "date",
        width: 150,
        sortable: true,
        filter: true,
        type: "dateTime",
        renderCell: (params) => {
          return (
            <div>
              {new Date(params.value)
                .toISOString()
                .slice(0, 19)
                .replace(/-/g, "/")
                .replace("T", " ")}
            </div>
          );
        },
      },
      /* ID */
      {
        headerName: "Id",
        field: "id",
        width: 70,
        sortable: true,
        filter: true,
      },
      /* Socio */
      {
        headerName: "Socio",
        field: "member",
        width: 150,
        sortable: true,
        filter: true,
      },
      /* Productos */
      {
        headerName: "Productos",
        field: "products",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      {
        headerName: "Descuento",
        field: "discount",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      /* Total €*/
      {
        headerName: "Total €",
        field: "total",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: (params) => {
          return currencyFormatter.format(params.value);
        },
      },
      /* Total W*/
      {
        headerName: "Peso Total",
        field: "weight",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: (params) => {
          return gramsFormatter.format(params.value) + " g";
        },
      },
      /* Firma */
      {
        headerName: "Firma",
        field: "signature",
        width: 125,
        renderCell: (params) => {
          return (
            <div>
              <img src={params.value} alt="signature" style={{ height: "25px", width: "125px" }} />
            </div>
          );
        },
      },

      /* Acciones */
      {
        headerName: "Acciones",
        field: "actions",
        width: 200,
        renderCell: true
          ? (rowData) => {
              return (
                <div>
                  <Link to={`/transactions/${rowData.id}`}>
                    <Button className="btn btn-sm m-2" variant="success">
                      Ver
                    </Button>
                  </Link>{" "}
                  {/* {userInfo && userInfo.permissions && userInfo.permissions["members"]["update"] && (
                    <Link to={`/transactions/edit/${rowData.id}`}>
                      <Button className="btn btn-sm m-2" variant="warning">
                        Editar
                      </Button>
                    </Link>
                  )} */}
                  {userInfo &&
                    userInfo.permissions &&
                    userInfo.permissions["members"]["delete"] && (
                      <Button
                        variant="danger"
                        onClick={() => deleteHandler(rowData.id)}
                        className=" btn btn-sm m-2"
                      >
                        Eliminar
                      </Button>
                    )}
                </div>
              );
            }
          : false,
      },
    ]);
  };

  const customButton = () => {
    if (organisationInfo) {
      return (
        <Link to="/transactions/create">
          <Button className="m-2" variant="success">
            Nueva Transacción
          </Button>
        </Link>
      );
    } else {
      return (
        <Link to="/organisation/create">
          <Button className="btn btn-sm m-2" variant="success">
            Nueva Organización
          </Button>
        </Link>
      );
    }
  };

  useEffect(() => {
    dispatch(listTransactions());
    if (!userInfo) window.location.href = "/login";
  }, [dispatch, success, userInfo]);

  useEffect(() => {
    if (transactions && transactions.transactions && transactions.transactions.length > 0) {
      defineDataGrid(transactions);
    }
  }, [transactions]);

  return (
    <MainScreen
      title={`Transacciones`}
      button={
        userInfo &&
        userInfo.permissions &&
        userInfo.permissions["transactions"]["create"] &&
        customButton()
      }
    >
      <Row>
        {loading && <Loading />}
        {transactions && transactions.transactions && (
          <div>
            <StripedDataGridWithFooter
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              sortModel={[{ field: "date", sort: "desc" }]}
            />
          </div>
        )}
      </Row>
      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
    </MainScreen>
  );
};
