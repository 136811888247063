import React from "react";
import { Card, Col, Row, Table, Button } from "react-bootstrap";
import placeholder from "../../assets/product_placeholder.jpeg";

/* import css */
import "./index.css";

const toBase64 = (file) => {
  if (!file) return "";

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const ProductCard = (props) => {
  const {
    type,
    name,
    category,
    description,
    price,
    weight,
    image,
    ean,
    setEan,
    setCategory,
    setName,
    setDescription,
    setPrice,
    setWeight,
    setImage,
  } = props;

  const read = "read";

  return (
    <Row className="align-items-center">
      <Col md={3}>
        <img
          src={image ? image : placeholder}
          alt="productImage"
          className="img-fluid rounded-circle shadow productImage"
          style={{ height: "300px", width: "300px" }}
          onClick={(e) => {
            /* click memberImageInput */
            document.getElementById("productImageInput").click();
          }}
        />
        <input
          className="d-none"
          type="file"
          id="productImageInput"
          accept="image/*"
          onChange={async (e) => {
            if (e.target.files[0].size > 5000000) {
              alert("El tamaño de la imagen no puede ser mayor a 5MB");
              return;
            }
            setImage(await toBase64(e.target.files[0]));
          }}
        ></input>
      </Col>
      <Col md={9}>
        <Row>
          <Col md={6}>
            <Table striped="columns">
              <tbody>
                {/* Name */}
                <tr>
                  <th>Nombre</th>
                  {type === read && <td className="customValue">{name}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
                {/* Address */}
                <tr>
                  <th>Descripción</th>
                  {type === read && <td className="customValue">{description}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
                {/* City */}
                <tr>
                  <th>EAN</th>
                  {type === read && <td className="customValue">{ean}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={ean}
                        onChange={(e) => setEan(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={6}>
            <Table striped="columns">
              <tbody>
                {/* Category */}
                <tr>
                  <th>Categoría</th>
                  {type === read && <td className="customValue">{category}</td>}
                  {type !== read && (
                    <td>
                      <select
                        required
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="customInput"
                      >
                        <option value="">Seleccione una categoría</option>
                        <option value="cannabis">Cannabis</option>
                        <option value="hachis">Hachis</option>
                        <option value="cbd">CBD</option>
                        <option value="bar">Bar</option>
                        <option value="otros">Otros</option>
                      </select>
                    </td>
                  )}
                </tr>

                {/* Phone */}
                <tr>
                  <th>Precio</th>
                  {type === read && <td className="customValue">{price}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
                {/* Email */}
                <tr>
                  <th>Peso</th>
                  {type === read && <td className="customValue">{weight}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductCard;
