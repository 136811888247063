import React from "react";
import { useState } from "react";
import { Card, Col, Button, ButtonGroup } from "react-bootstrap";

import {
  faUser,
  faCannabis,
  faEuro,
  faWeightScale,
  faCashRegister,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";

import "./typeFilter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TypeFilter = (props) => {
  let { active, setFilter } = props;

  const FilterButton = (props) => {
    let { title, value } = props;
    return (
      <Button
        variant="secondary"
        className={active === value ? "active" : ""}
        onClick={() => {
          setFilter(value);
        }}
      >
        {title}
      </Button>
    );
  };

  return (
    <div className="typeFilter">
      <ButtonGroup>
        <FilterButton value="income" title="Ingreso" />
        <FilterButton value="sells" title="Ventas" />
        <FilterButton value="transactionsCount" title="Transacciones" />
        <FilterButton value="entriesCount" title="Entradas" />
      </ButtonGroup>
    </div>
  );
};

export default TypeFilter;
