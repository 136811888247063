import {
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
  ORGANISATION_LIST_FAILURE,
  ORGANISATION_CREATE_REQUEST,
  ORGANISATION_CREATE_SUCCESS,
  ORGANISATION_CREATE_FAILURE,
  ORGANISATION_UPDATE_REQUEST,
  ORGANISATION_UPDATE_SUCCESS,
  ORGANISATION_UPDATE_FAILURE,
  ORGANISATION_DELETE_REQUEST,
  ORGANISATION_DELETE_SUCCESS,
  ORGANISATION_DELETE_FAILURE,
} from "../constants/organisations";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listEntries = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_LIST_REQUEST });

    const { data } = await axios.get("/api/organisations", apiConfig);

    dispatch({ type: ORGANISATION_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ORGANISATION_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getOrganisationById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_LIST_REQUEST });

    const { data } = await axios.get(`/api/organisations/${id}`, apiConfig);
    const users = await axios.get(`/api/organisations/${id}/users`, apiConfig);

    dispatch({
      type: ORGANISATION_LIST_SUCCESS,
      payload: { ...data, users: users.data.users },
    });
  } catch (err) {
    dispatch({
      type: ORGANISATION_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createOrganisationAction =
  (name, email, address, phone, userId) => async (dispatch, getState) => {
    dispatch({ type: ORGANISATION_CREATE_REQUEST });

    try {
      const { data } = await axios.post(
        "/api/organisations/create",
        {
          name: name,
          email: email,
          address: address,
          phone: phone,
          userId: userId,
        },
        apiConfig
      );

      dispatch({ type: ORGANISATION_CREATE_SUCCESS, payload: data });
      localStorage.setItem("organisationInfo", JSON.stringify(data.organisation));
      let currUserInfo = localStorage.getItem("userInfo");
      currUserInfo = JSON.parse(currUserInfo);
      currUserInfo.organisation = data.organisation._id;
      localStorage.setItem("userInfo", JSON.stringify(currUserInfo));
    } catch (err) {
      dispatch({
        type: ORGANISATION_CREATE_FAILURE,
        payload:
          err.response && err.response.data.message ? err.response.data.message : err.message,
      });
    }
  };

export const updateOrganisationAction =
  (id, name, email, address, phone, userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANISATION_UPDATE_REQUEST });

      const { data } = await axios.put(
        `/api/organisations/${id}`,
        {
          name,
          email,
          address,
          phone,
          userId,
        },
        apiConfig
      );

      localStorage.setItem("organisationInfo", JSON.stringify(data.organisation));

      dispatch({
        type: ORGANISATION_UPDATE_SUCCESS,
        payload: data.organisation,
        message: data.message,
      });
    } catch (err) {
      dispatch({
        type: ORGANISATION_UPDATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const deleteOrganisationAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/organisations/${id}`, apiConfig);

    dispatch({ type: ORGANISATION_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ORGANISATION_DELETE_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createUser =
  (name, email, password, confirmPassword, organisationId, permissions) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANISATION_UPDATE_REQUEST });

      const { data } = await axios.post(
        `/api/organisations/addUser/${organisationId}`,
        {
          username: name,
          email: email,
          password: password,
          confirmPassword,
          permissions,
        },
        apiConfig
      );

      localStorage.setItem("organisationInfo", JSON.stringify(data.organisation));

      dispatch({
        type: ORGANISATION_UPDATE_SUCCESS,
        payload: data.organisation,
        message: data.message,
      });
    } catch (err) {
      dispatch({
        type: ORGANISATION_UPDATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const updateUser = (id, name, email, permissions) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_UPDATE_REQUEST });

    const { data } = await axios.put(
      `/api/organisations/editUser/${id}`,
      {
        username: name,
        email,
        permissions,
      },
      apiConfig
    );

    dispatch({
      type: ORGANISATION_UPDATE_SUCCESS,
      payload: data.organisation,
      message: data.message,
    });
  } catch (err) {
    dispatch({
      type: ORGANISATION_UPDATE_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_UPDATE_REQUEST });

    const { data } = await axios.delete(`/api/users/${id}`, apiConfig);

    if (data.organisation)
      localStorage.setItem("organisationInfo", JSON.stringify(data.organisation));

    dispatch({
      type: ORGANISATION_UPDATE_SUCCESS,
      payload: data.organisation,
    });
  } catch (err) {
    dispatch({
      type: ORGANISATION_UPDATE_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
