import { React, useState, useEffect } from "react";
import { Card, Col, Row, Table, Button } from "react-bootstrap";
import ProductSelect from "components/productSelect";

/* import css */
import "./index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

function removeProduct(index, setProductsArray, productsArray) {
  let newArray = [...productsArray];
  newArray.splice(index, 1);
  setProductsArray(newArray);
}

function addNewProduct(setProductsArray, productsArray) {
  setProductsArray([
    ...productsArray,
    {
      _id: "",
      quantity: 1,
      stamps: {
        price: 0,
        weight: 0,
      },
      discount: 0,
    },
  ]);
}

const TxTable = (props) => {
  const { productsArray, setProductsArray, products, type, productsAvailable, screen } = props;
  const [productsFromSupplier, setProductsFromSupplier] = useState([]);

  function setProdsFromSupplier(products, productsAvailable) {
    let _productsFromSupplier = [];
    products &&
      products.forEach((product) => {
        productsAvailable.forEach((productAvailable) => {
          product &&
            product._id &&
            productAvailable &&
            productAvailable._id &&
            product._id === productAvailable._id._id &&
            _productsFromSupplier.push(product);
        });
      });
    setProductsFromSupplier(_productsFromSupplier);
  }

  useEffect(() => {
    setProductsFromSupplier([]);
    products && productsAvailable && setProdsFromSupplier(products.products, productsAvailable);
  }, [products, productsAvailable]);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th className="text-center">Acciones</th>
          <th className="text-center">Producto</th>
          <th className="text-center">Peso u.</th>
          <th className="text-center">Precio u.</th>
          <th className="text-center">Cantidad</th>
          <th className="text-center">Descuento %</th>
          <th className="text-center">Peso Total</th>
          <th className="text-center">Precio Total</th>
        </tr>
      </thead>
      <tbody>
        {type === "read" &&
          productsArray.map((product, index) => {
            return (
              <tr key={index} className="align-middle">
                <td></td>
                <td className="text-start">{product._id.name}</td>
                <td className="text-end">{product.stamps.weight.toFixed(2)} g</td>
                <td className="text-end">{product.stamps.price.toFixed(2)} €</td>
                <td className="text-end">{product.quantity} uds.</td>

                <td className="text-end">
                  {product.discount > 0 ? product.discount.toFixed(2) : "0.00"} %
                </td>
                <td className="text-end">
                  {(product.quantity * product.stamps.weight).toFixed(2)} g
                </td>
                <td className="text-end">
                  {(
                    product.quantity * product.stamps.price -
                    product.quantity * product.stamps.price * (product.discount / 100)
                  ).toFixed(2)}{" "}
                  €
                </td>
              </tr>
            );
          })}

        {type !== "read" &&
          productsArray.map((product, index) => {
            return (
              <tr key={index} className="align-middle">
                <td>
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => removeProduct(index, setProductsArray, productsArray)}
                  >
                    <FontAwesomeIcon icon={faTrash} style={{ color: "white" }} /> Borrar
                  </Button>
                </td>
                <td className="text-start">
                  {screen === "purchase" &&
                    productsFromSupplier &&
                    products &&
                    products.products &&
                    product && (
                      <ProductSelect
                        products={productsFromSupplier}
                        product={product}
                        productsArray={productsArray}
                        setProductsArray={setProductsArray}
                        index={index}
                      />
                    )}

                  {screen === "transaction" && products && products.products && product && (
                    <ProductSelect
                      products={products.products}
                      product={product}
                      productsArray={productsArray}
                      setProductsArray={setProductsArray}
                      index={index}
                    />
                  )}
                </td>
                <td className="text-end">{product.stamps.weight.toFixed(2)} g</td>
                <td className="text-end">{product.stamps.price.toFixed(2)} €</td>
                <td className="text-end">
                  <input
                    style={{
                      width: "50px",
                    }}
                    type="number"
                    placeholder="Unidades"
                    onChange={(e) => {
                      productsArray[index].quantity = e.target.value;
                      setProductsArray([...productsArray]);
                    }}
                    value={product.quantity}
                    required
                  ></input>{" "}
                  uds.
                </td>
                <td className="text-end">
                  <input
                    style={{
                      width: "50px",
                    }}
                    step="0.1"
                    type="number"
                    placeholder={0}
                    onChange={(e) => {
                      productsArray[index].discount = e.target.value;
                      setProductsArray([...productsArray]);
                    }}
                    value={product.discount}
                    required
                  ></input>{" "}
                  %
                </td>
                <td className="text-end">
                  {(product.quantity * product.stamps.weight).toFixed(2)} g
                </td>
                <td className="text-end">
                  {(
                    product.quantity * product.stamps.price -
                    product.quantity * product.stamps.price * (product.discount / 100)
                  ).toFixed(2)}{" "}
                  €
                </td>
              </tr>
            );
          })}

        <tr
          style={{
            backgroundColor: "rgb(230 243 255)",
            fontWeight: "bold",
          }}
        >
          <th>
            {type !== "read" && (
              <Button
                variant="success"
                className="btn-sm"
                onClick={() => addNewProduct(setProductsArray, productsArray)}
              >
                <FontAwesomeIcon icon={faPlus} style={{ color: "white" }} /> Añadir
              </Button>
            )}
          </th>
          <th colSpan="5">Total: </th>

          <th className="text-end">
            {productsArray
              .reduce((total, product) => {
                return total + product.quantity * product.stamps.weight;
              }, 0)
              .toFixed(2) + " g"}
          </th>
          <th className="text-end">
            {productsArray
              .reduce((total, product) => {
                return (
                  total +
                  product.quantity * product.stamps.price -
                  product.quantity * product.stamps.price * (product.discount / 100)
                );
              }, 0)
              .toFixed(2) + " €"}
          </th>
        </tr>
      </tbody>
    </Table>
  );
};

export default TxTable;
