import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Col, Row, Card, Table } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { getMemberById, listMembers } from "redux/actions/members";
import UserCard from "components/userCard";
import axios from "axios";

export default () => {
  const [membershipId, setMembershipId] = useState("");
  const [nickname, setNickname] = useState("");
  const [referrer, setReferrer] = useState("");
  const [referees, setReferees] = useState([]);
  const [consumerType, setConsumerType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [image, setImage] = useState("");
  const [gender, setGender] = useState("");
  const [dni, setDni] = useState("");
  const [dniFront, setDniFront] = useState("");
  const [dniBack, setDniBack] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [membershipDate, setMembershipDate] = useState("");
  const [monthlyConsumptionForecast, setMonthlyConsumptionForecast] = useState(0);
  const [date, setDate] = useState("");
  const [contract, setContract] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const membersReducer = useSelector((state) => state.members);
  const { member, members, loading, error, success } = membersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    setMembershipId("");
    setNickname("");
    setReferrer("");
    setReferees([]);
    setConsumerType("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCity("");
    setGender("");
    setDni("");
    setDniFront("");
    setDniBack("");
    setBirthDate("");
    setMembershipDate("");
    setMonthlyConsumptionForecast(0);
    setImage("");
    setContract("");

    window.location.href = "/members";
  };

  const fetchData = async () => {
    setDate(member.date);
    setMembershipId(member.membershipId);
    setNickname(member.nickname);
    setReferrer(member.referrer);
    setReferees(member.referees);
    setConsumerType(member.consumerType);
    setFirstName(member.firstName);
    setLastName(member.lastName);
    setEmail(member.email);
    setPhone(member.phone);
    setAddress(member.address);
    setCity(member.city);
    setGender(member.gender);
    setDni(member.dni);
    setDniFront(member.dniFront);
    setDniBack(member.dniBack);
    setBirthDate(new Date(member.birthDate).toISOString().substring(0, 10));
    setMembershipDate(new Date(member.membershipDate).toISOString().substring(0, 10));
    setMonthlyConsumptionForecast(member.monthlyConsumptionForecast);
    setImage(member.image);
    setContract(member.contract);
  };

  useEffect(() => {
    dispatch(listMembers());
    dispatch(getMemberById(id));
    if (!userInfo) window.location.href = "/";
    if (success) resetHandler();
  }, [userInfo, success]);

  useEffect(() => {
    if (member) fetchData();
  }, [member]);

  if (loading) {
    return (
      <MainScreen title="Visualizar Socio">
        <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Visualizar Socio">
        <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
          <UserCard
            members={members}
            membershipId={membershipId}
            nickname={nickname}
            referrer={referrer}
            referees={referees}
            consumerType={consumerType}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            address={address}
            city={city}
            image={image}
            membershipDate={membershipDate}
            monthlyConsumptionForecast={monthlyConsumptionForecast}
            dniFront={dniFront}
            dniBack={dniBack}
            birthDate={birthDate}
            gender={gender}
            dni={dni}
            contract={contract}
            type="read"
          />
        </div>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      </MainScreen>
    );
  }
};
