export const ENTRY_LIST_REQUEST = "ENTRY_LIST_REQUEST";
export const ENTRY_LIST_SUCCESS = "ENTRY_LIST_SUCCESS";
export const ENTRY_LIST_FAILURE = "ENTRY_LIST_FAILURE";

export const ENTRY_CREATE_REQUEST = "ENTRY_CREATE_REQUEST";
export const ENTRY_CREATE_SUCCESS = "ENTRY_CREATE_SUCCESS";
export const ENTRY_CREATE_FAILURE = "ENTRY_CREATE_FAILURE";

export const ENTRY_UPDATE_REQUEST = "ENTRY_UPDATE_REQUEST";
export const ENTRY_UPDATE_SUCCESS = "ENTRY_UPDATE_SUCCESS";
export const ENTRY_UPDATE_FAILURE = "ENTRY_UPDATE_FAILURE";

export const ENTRY_DELETE_REQUEST = "ENTRY_DELETE_REQUEST";
export const ENTRY_DELETE_SUCCESS = "ENTRY_DELETE_SUCCESS";
export const ENTRY_DELETE_FAILURE = "ENTRY_DELETE_FAILURE";

export const RESET_ENTRIES = "RESET_ENTRIES";
