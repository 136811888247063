export const ORGANISATION_LIST_REQUEST = "ORGANISATION_LIST_REQUEST";
export const ORGANISATION_LIST_SUCCESS = "ORGANISATION_LIST_SUCCESS";
export const ORGANISATION_LIST_FAILURE = "ORGANISATION_LIST_FAILURE";

export const ORGANISATION_CREATE_REQUEST = "ORGANISATION_CREATE_REQUEST";
export const ORGANISATION_CREATE_SUCCESS = "ORGANISATION_CREATE_SUCCESS";
export const ORGANISATION_CREATE_FAILURE = "ORGANISATION_CREATE_FAILURE";

export const ORGANISATION_UPDATE_REQUEST = "ORGANISATION_UPDATE_REQUEST";
export const ORGANISATION_UPDATE_SUCCESS = "ORGANISATION_UPDATE_SUCCESS";
export const ORGANISATION_UPDATE_FAILURE = "ORGANISATION_UPDATE_FAILURE";

export const ORGANISATION_DELETE_REQUEST = "ORGANISATION_DELETE_REQUEST";
export const ORGANISATION_DELETE_SUCCESS = "ORGANISATION_DELETE_SUCCESS";
export const ORGANISATION_DELETE_FAILURE = "ORGANISATION_DELETE_FAILURE";
