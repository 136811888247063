import React from "react";
import { Alert } from "react-bootstrap";

const ErrorMessage = ({ variant = "info", children }) => {
  const style = {
    whiteSpace: "pre-wrap",
    verticalAlign: "middle",
  };

  return (
    <Alert variant={variant} style={style}>
      <span>{children && children.message ? children.message : children}</span>
    </Alert>
  );
};

export default ErrorMessage;
