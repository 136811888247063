import React from "react";
import { useState } from "react";
import { Card, Col, Button, ButtonGroup } from "react-bootstrap";

import {
  faUser,
  faCannabis,
  faEuro,
  faWeightScale,
  faCashRegister,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";

import "./timeFilter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TimeFilter = (props) => {
  let { active, setFilter } = props;

  const FilterButton = (props) => {
    let { title, value } = props;
    return (
      <Button
        variant="secondary"
        className={active === value ? "active" : ""}
        onClick={() => {
          setFilter(value);
        }}
      >
        {title}
      </Button>
    );
  };

  return (
    <div className="timeFilter">
      <ButtonGroup>
        <FilterButton value="today" title="Hoy" />
        <FilterButton value="yesterday" title="Ayer" />
        <FilterButton value="lastweek" title="7 días" />
        <FilterButton value="lastmonth" title="30 días" />
      </ButtonGroup>
    </div>
  );
};

export default TimeFilter;
