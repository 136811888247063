import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { updatePurchaseAction, getPurchaseById } from "redux/actions/purchases";
import TopazSignature from "components/custom/topazSignature";
import moment from "moment";
import axios from "axios";

import { addNewProduct, resetHandler, editHandler, removeProduct } from "../helpers/functions";
import TxTable from "components/txTable";
import { PdfInput } from "components/imageInput";

import { listener, startTablet } from "utils/SigWebTablet";

import { listProducts } from "redux/actions/products";
import { listSuppliers } from "redux/actions/suppliers";

export default () => {
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [date, setDate] = useState("");
  const [supplier, setSupplier] = useState("");
  const [productsArray, setProductsArray] = useState([]);
  const [bill, setBill] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  /* Redux State */
  const productsReducer = useSelector((state) => state.products);
  const { products } = productsReducer;

  const purchasesReducer = useSelector((state) => state.purchases);
  const { purchases, loading, error, success } = purchasesReducer;

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { suppliers } = suppliersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const fetchData = async () => {
    setProductsArray(purchases.purchase.products);
    setDate(new Date(purchases.purchase.date).toISOString().slice(0, 16));
    setBill(purchases.purchase.bill);
    setSupplier(
      suppliers.suppliers.find((supplier) => supplier._id === purchases.purchase.supplier._id)
    );
    setTotal(purchases.purchase.total_cost);
    setTotalWeight(purchases.purchase.total_weight);
  };

  /* useEffect */
  useEffect(() => {
    dispatch(getPurchaseById(id));
    dispatch(listProducts());
    dispatch(listSuppliers());

    if (!userInfo) window.location.href = "/";
    if (success) resetHandler(setProductsArray, setTotal, setTotalWeight, setDate);
    setDate(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
  }, [userInfo, success, dispatch]);

  useEffect(() => {
    async function fetch() {
      if (purchases && purchases.purchase && suppliers) await fetchData();
    }
    fetch();
  }, [suppliers, purchases]);

  if (loading) {
    return (
      <MainScreen title="Editar Compra">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Editar Compra">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <p className="my-3 text-muted">
            Fecha Edición - {new Date().toLocaleDateString("es-ES")}
          </p>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (
                productsArray.reduce((acc, item) => acc + item.quantity * item.stamps.price, 0) <
                supplier.minPurchase
              )
                alert(
                  "El monto de la compra no puede ser menor al monto mínimo de compra del proveedor: " +
                    supplier.minPurchase
                );
              else dispatch(updatePurchaseAction(id, productsArray, date, supplier._id, bill));
            }}
          >
            <Row>
              {/* Fecha */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-date">
                  <Form.Label>Fecha / Hora Compra</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    required
                  />
                </Form.Group>
              </Col>
              {/* Proveedor */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-supplier">
                  <Form.Label>Proveedor</Form.Label>
                  {suppliers && suppliers.suppliers ? (
                    <Form.Select
                      onChange={(e) =>
                        setSupplier(
                          suppliers.suppliers.find((supplier) => supplier._id === e.target.value)
                        )
                      }
                      value={supplier ? supplier._id : ""}
                      required
                    >
                      <option value="">Seleccionar Proveedor</option>
                      {suppliers &&
                        suppliers.suppliers &&
                        suppliers.suppliers.map((supplier) => (
                          <option key={supplier._id} value={supplier._id}>
                            {supplier.name}
                          </option>
                        ))}
                    </Form.Select>
                  ) : (
                    <Form.Control type="text" placeholder="No hay Proveedores" disabled />
                  )}
                </Form.Group>
              </Col>
              <Row>
                <Col md={3}>
                  <Form.Label>Factura</Form.Label>
                  <PdfInput value={bill} setValue={setBill} customRef="billId" />
                </Col>
                <Col md={6}></Col>
              </Row>
            </Row>
            <p className="my-3 text-muted">Productos:</p>
            <TxTable
              productsArray={productsArray}
              setProductsArray={setProductsArray}
              products={products}
              productsAvailable={supplier ? supplier.products : []}
              type="update"
              screen="purchase"
            />
            <Row>
              <Col md={12}>
                <Button variant="primary" type="submit" className="m-1">
                  Actualizar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {success && <ErrorMessage variant="success">Purchase Update Successfully</ErrorMessage>}
      </MainScreen>
    );
  }
};
