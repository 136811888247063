import { React, useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import { Hint, Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

const getProduct = (products, id) => {
  console.log(products);
  for (let i = 0; i < products.products.length; i++) {
    if (products.products[i]._id == id) {
      console.log(products.products[i]);
      return products.products[i];
    }
  }
};

const ProductSelect = (props) => {
  const { products, product, productsArray, setProductsArray, index, disabled } = props;
  const [selected, setSelected] = useState(
    product && product._id && products
      ? [
          {
            id: product._id._id,
            label: getProduct(products, product._id).name,
          },
        ]
      : []
  );

  useEffect(() => {
    console.log(product, products);
    product && product._id && products
      ? setSelected([
          {
            id: product._id._id,
            label: getProduct(products, product._id).name,
          },
        ])
      : setSelected([]);
  }, [product, products, productsArray]);

  return !disabled ? (
    <Typeahead
      clearButton
      id="productSelect"
      onChange={(selected) => {
        setSelected(selected);
        if (selected.length) {
          /* check if is already on array */
          if (productsArray.find((p) => p._id === selected[0].id)) alert("Producto ya añadido");
          else {
            productsArray[index]._id = selected[0].id;
            setProductsArray([...productsArray]);
          }
        }
      }}
      options={
        products.products
          ? products.products.map((product) => {
              return {
                id: product._id,
                label: product.name,
              };
            })
          : []
      }
      labelKey={(option) => `${option.label}`}
      placeholder="Selecciona Producto"
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
        return (
          <Hint>
            <Form.Control
              {...inputProps}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />
          </Hint>
        );
      }}
      selected={selected ? selected : []}
    />
  ) : (
    <Form.Control type="text" value={selected[0].label} disabled />
  );
};

export default ProductSelect;
