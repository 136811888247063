import {
  TRANSACTION_LIST_REQUEST,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILURE,
  TRANSACTION_CREATE_REQUEST,
  TRANSACTION_CREATE_SUCCESS,
  TRANSACTION_CREATE_FAILURE,
  TRANSACTION_UPDATE_REQUEST,
  TRANSACTION_UPDATE_SUCCESS,
  TRANSACTION_UPDATE_FAILURE,
  TRANSACTION_DELETE_REQUEST,
  TRANSACTION_DELETE_SUCCESS,
  TRANSACTION_DELETE_FAILURE,
} from "../constants/transactions";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listTransactions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TRANSACTION_LIST_REQUEST });

    const { data } = await axios.get("/api/transactions", apiConfig);

    dispatch({ type: TRANSACTION_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: TRANSACTION_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getTransactionsByDate = (gtDate, ltDate) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRANSACTION_LIST_REQUEST });

    const { data } = await axios.post(
      "/api/transactions/date",
      {
        gtDate,
        ltDate,
      },
      apiConfig
    );

    dispatch({ type: TRANSACTION_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: TRANSACTION_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getTransactionById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRANSACTION_LIST_REQUEST });

    const { data } = await axios.get(`/api/transactions/${id}`, apiConfig);

    dispatch({ type: TRANSACTION_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: TRANSACTION_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createTransactionAction =
  (member, productsArray, signature, date, total, totalWeight) => async (dispatch, getState) => {
    try {
      dispatch({ type: TRANSACTION_CREATE_REQUEST });

      const { data } = await axios.post(
        "/api/transactions/create",
        {
          member,
          productsArray,
          signature,
          date,
        },
        apiConfig
      );

      dispatch({ type: TRANSACTION_CREATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: TRANSACTION_CREATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const updateTransactionAction =
  (id, member, productsArray, signature, date) => async (dispatch, getState) => {
    try {
      dispatch({ type: TRANSACTION_UPDATE_REQUEST });

      const { data } = await axios.put(
        `/api/transactions/${id}`,
        {
          member,
          productsArray,
          signature,
          date,
        },
        apiConfig
      );

      dispatch({ type: TRANSACTION_UPDATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: TRANSACTION_UPDATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const deleteTransactionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRANSACTION_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/transactions/${id}`, apiConfig);

    dispatch({ type: TRANSACTION_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: TRANSACTION_DELETE_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
