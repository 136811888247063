import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import MemberSelect from "components/memberSelect";

import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { createEntryAction } from "redux/actions/entries";
import moment from "moment";

import { listMembers } from "redux/actions/members";

export default () => {
  const [member, setMember] = useState({
    id: "",
    label: "",
  });
  const [entryDate, setEntryDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  // Reset entriesReducer
  useEffect(() => {
    dispatch({ type: "RESET_ENTRIES" });
  }, [dispatch]);

  const entriesReducer = useSelector((state) => state.entries);
  const { loading, error, success } = entriesReducer;

  const membersReducer = useSelector((state) => state.members);
  const { members } = membersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    setMember("");
    setEntryDate("");
    window.location.href = "/entries";
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    dispatch(createEntryAction(member.id, entryDate));
  };

  useEffect(() => {
    if (!userInfo) window.location.href = "/";
    if (success) resetHandler();
    dispatch(listMembers());
    setEntryDate(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
  }, [userInfo, success, dispatch]);

  useEffect(() => {
    if (searchParams.get("userId") && members) {
      members.map((m) => {
        if (m.membershipId === searchParams.get("userId")) {
          setMember({
            id: m.membershipId,
            label: m.membershipId + " - " + m.firstName + " " + m.lastName,
          });
        }
      });
    }
  }, [members]);

  if (loading) {
    return (
      <MainScreen title="Crear Entrada">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Crear Entrada">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <p className="my-3 text-muted">
            Fecha Creación - {new Date().toLocaleDateString("es-ES")}
          </p>
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="form-memberId">
              <Form.Label>Socio</Form.Label>
              {member && members && members && members.length ? (
                <MemberSelect members={members} member={member} setMember={setMember} />
              ) : (
                <Form.Control type="text" placeholder="No hay socios" disabled />
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="form-entryDate">
              <Form.Label>Fecha / Hora Entrada</Form.Label>
              <Form.Control
                type="datetime-local"
                placeholder="Enter Content"
                onChange={(e) => setEntryDate(e.target.value)}
                value={entryDate}
                required
              />
            </Form.Group>

            {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
            {success && <ErrorMessage variant="success">Entry Created Successfully</ErrorMessage>}
            <Button variant="primary" type="submit">
              Crear
            </Button>
          </Form>
        </div>
      </MainScreen>
    );
  }
};
