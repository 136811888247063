import {
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
  ORGANISATION_LIST_FAILURE,
  ORGANISATION_CREATE_REQUEST,
  ORGANISATION_CREATE_SUCCESS,
  ORGANISATION_CREATE_FAILURE,
  ORGANISATION_UPDATE_REQUEST,
  ORGANISATION_UPDATE_SUCCESS,
  ORGANISATION_UPDATE_FAILURE,
  ORGANISATION_DELETE_REQUEST,
  ORGANISATION_DELETE_SUCCESS,
  ORGANISATION_DELETE_FAILURE,
} from "../constants/organisations";

export const organisationReducer = (state = {}, action) => {
  switch (action.type) {
    /* Organisation List */
    case ORGANISATION_LIST_REQUEST:
      return {
        loading: true,
      };
    case ORGANISATION_LIST_SUCCESS:
      return {
        loading: false,
        organisationInfo: action.payload.organisation,
      };
    case ORGANISATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /* Organisation Create */
    case ORGANISATION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORGANISATION_CREATE_SUCCESS:
      return {
        loading: false,
        organisationInfo: action.payload,
        success: true,
      };
    case ORGANISATION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ORGANISATION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORGANISATION_UPDATE_SUCCESS:
      return {
        loading: false,
        organisationInfo: action.payload,
        success: action.message,
        error: false,
      };
    case ORGANISATION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ORGANISATION_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORGANISATION_DELETE_SUCCESS:
      return {
        loading: false,
        organisation: action.payload,
        success: true,
      };
    case ORGANISATION_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
