import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAILURE,
  SUPPLIER_CREATE_REQUEST,
  SUPPLIER_CREATE_SUCCESS,
  SUPPLIER_CREATE_FAILURE,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_FAILURE,
  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAILURE,
  SUPPLIER_RESET,
} from "../constants/suppliers";

export const suppliersReducer = (state = { suppliers: [] }, action) => {
  switch (action.type) {
    case SUPPLIER_LIST_REQUEST:
      return { loading: true };
    case SUPPLIER_LIST_SUCCESS:
      return { loading: false, suppliers: action.payload };
    case SUPPLIER_LIST_FAILURE:
      return { loading: false, error: action.payload };
    case SUPPLIER_CREATE_REQUEST:
      return { loading: true };
    case SUPPLIER_CREATE_SUCCESS:
      return { loading: false, success: true };
    case SUPPLIER_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case SUPPLIER_UPDATE_REQUEST:
      return { loading: true };
    case SUPPLIER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case SUPPLIER_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case SUPPLIER_DELETE_REQUEST:
      return { loading: true };
    case SUPPLIER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SUPPLIER_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case SUPPLIER_RESET:
      return {};

    default:
      return state;
  }
};
