export const MEMBER_LIST_REQUEST = "MEMBER_LIST_REQUEST";
export const MEMBER_LIST_SUCCESS = "MEMBER_LIST_SUCCESS";
export const MEMBER_LIST_FAILURE = "MEMBER_LIST_FAILURE";

export const MEMBER_INDIVIDUAL_REQUEST = "MEMBER_IDIVIDUAL_REQUEST";
export const MEMBER_INDIVIDUAL_SUCCESS = "MEMBER_IDIVIDUAL_SUCCESS";
export const MEMBER_INDIVIDUAL_FAILURE = "MEMBER_IDIVIDUAL_FAILURE";

export const MEMBER_CREATE_REQUEST = "MEMBER_CREATE_REQUEST";
export const MEMBER_CREATE_SUCCESS = "MEMBER_CREATE_SUCCESS";
export const MEMBER_CREATE_FAILURE = "MEMBER_CREATE_FAILURE";

export const MEMBER_UPDATE_REQUEST = "MEMBER_UPDATE_REQUEST";
export const MEMBER_UPDATE_SUCCESS = "MEMBER_UPDATE_SUCCESS";
export const MEMBER_UPDATE_FAILURE = "MEMBER_UPDATE_FAILURE";

export const MEMBER_DELETE_REQUEST = "MEMBER_DELETE_REQUEST";
export const MEMBER_DELETE_SUCCESS = "MEMBER_DELETE_SUCCESS";
export const MEMBER_DELETE_FAILURE = "MEMBER_DELETE_FAILURE";
