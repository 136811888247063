import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/custom/loading";
import ErrorMessage from "../../../components/custom/errorMessage";
import { createOrganisationAction } from "../../../redux/actions/organisations";

export default () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  //const [picture, setPicture] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { loading, error, success } = organisation;

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(createOrganisationAction(name, email, address, phone));
  };

  useEffect(() => {
    if (!userInfo) {
      window.location.href = "/dashboard";
    }
    if (userInfo.orgnanisation) window.location.href = "/myorganisation";
    if (success) window.location.href = "/myorganisation";
  }, [userInfo, success]);

  if (loading) {
    return (
      <MainScreen title="Crear Organización">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Crear Organización">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Form onSubmit={submitHandler}>
            {/* Name */}
            <Form.Group className="mb-3" controlId="formBasicUsername">
              <Form.Label>Nombre Organización</Form.Label>
              <Form.Control
                type="name"
                placeholder="Introduce Nombre Organización"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </Form.Group>
            {/* Email */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Introduce Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              <Form.Text className="text-muted">No compartiremos tu email con nadie.</Form.Text>
            </Form.Group>
            {/* Address */}
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="address"
                placeholder="Introduce Dirección"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                required
              />
            </Form.Group>
            {/* Phone */}
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="phone"
                placeholder="Introduce Teléfono"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Form>
        </div>
        {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
      </MainScreen>
    );
  }
};
