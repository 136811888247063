import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_PASSWORD_REQUEST,
  USER_PASSWORD_SUCCESS,
  USER_PASSWORD_FAILURE,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGOUT,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
} from "../constants/users";

import { permissionsModelTrue } from "utils/permissionsModel";
import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const { data } = await axios.post(
      "/api/users/login",
      {
        username: username,
        password: password,
      },
      apiConfig
    );

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    /* Set Local Storage Information */
    localStorage.setItem("userInfo", JSON.stringify(data.user));
    localStorage.setItem("organisationInfo", JSON.stringify(data.organisation));
    localStorage.setItem("token", data.token);
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_REQUEST });

    const { data } = await axios.post(
      "/api/users/forgotPassword",
      {
        email: email,
      },
      apiConfig
    );

    dispatch({ type: USER_PASSWORD_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_PASSWORD_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const resetPassword = (password, confirmPassword, token) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_REQUEST });

    const { data } = await axios.post(
      "/api/users/resetPassword",
      {
        password: password,
        confirmPassword: confirmPassword,
        token: token,
      },
      apiConfig
    );

    dispatch({ type: USER_PASSWORD_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_PASSWORD_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const updatePassword = (password, newPassword, newPasswordConfirm) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_REQUEST });

    const { data } = await axios.post(
      "/api/users/updatePassword",
      {
        password: password,
        newPassword: newPassword,
        newPasswordConfirm: newPasswordConfirm,
      },
      apiConfig
    );

    dispatch({ type: USER_PASSWORD_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_PASSWORD_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const register =
  (username, email, password, confirmPassword, role, permissions) => async (dispatch) => {
    try {
      dispatch({ type: USER_REGISTER_REQUEST });

      const { data } = await axios.post(
        "/api/users/register",
        {
          username: username,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
          role: role,
          permissions: permissions,
        },
        apiConfig
      );

      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: USER_REGISTER_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};

export const updateUserInfo =
  (id, username, email, organisation, password, role) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_UPDATE_REQUEST });

      const { data } = await axios.put(
        `/api/users/${id}`,
        {
          username: username,
          email: email,
        },
        apiConfig
      );

      localStorage.setItem("userInfo", JSON.stringify(data.user));
      dispatch({ type: USER_UPDATE_SUCCESS, payload: data.user });
    } catch (err) {
      dispatch({
        type: USER_UPDATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const getUserInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_GET_REQUEST });

    const { data } = await axios.get(`/api/users/${id}`, apiConfig);

    dispatch({ type: USER_GET_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_GET_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  /* delete user */
  try {
    dispatch({ type: USER_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/users/${id}`, apiConfig);

    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USER_DELETE_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};
