export const PURCHASE_LIST_REQUEST = "PURCHASE_LIST_REQUEST";
export const PURCHASE_LIST_SUCCESS = "PURCHASE_LIST_SUCCESS";
export const PURCHASE_LIST_FAILURE = "PURCHASE_LIST_FAILURE";

export const PURCHASE_CREATE_REQUEST = "PURCHASE_CREATE_REQUEST";
export const PURCHASE_CREATE_SUCCESS = "PURCHASE_CREATE_SUCCESS";
export const PURCHASE_CREATE_FAILURE = "PURCHASE_CREATE_FAILURE";

export const PURCHASE_UPDATE_REQUEST = "PURCHASE_UPDATE_REQUEST";
export const PURCHASE_UPDATE_SUCCESS = "PURCHASE_UPDATE_SUCCESS";
export const PURCHASE_UPDATE_FAILURE = "PURCHASE_UPDATE_FAILURE";

export const PURCHASE_DELETE_REQUEST = "PURCHASE_DELETE_REQUEST";
export const PURCHASE_DELETE_SUCCESS = "PURCHASE_DELETE_SUCCESS";
export const PURCHASE_DELETE_FAILURE = "PURCHASE_DELETE_FAILURE";

export const RESET_PURCHASES = "RESET_PURCHASES";
