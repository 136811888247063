import { useState } from "react";
import "./css/App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

/* Screens */

// LandingPage
import LandingPage from "./screens/landingPage/landingPage.js";

// Dashboard Screen
import Dashboard from "./screens/dashboard";

// Auth
import Login from "./screens/auth/login";
import Register from "./screens/auth/register";
import ForgotPassword from "./screens/auth/forgotPassword";
import ResetPassword from "./screens/auth/resetPassword";
// Members
import Members from "./screens/members";
import MembersConsumptions from "./screens/members/consumptions";
import SingleMember from "./screens/members/update";
import CreateMember from "./screens/members/create";
import ReadMember from "./screens/members/read";
// Suppliers
import Suppliers from "./screens/suppliers";
import SingleSupplier from "./screens/suppliers/update";
import CreateSupplier from "./screens/suppliers/create";
import ReadSupplier from "./screens/suppliers/read";
// Products
import Products from "./screens/products";
import SingleProduct from "./screens/products/update";
import CreateProduct from "./screens/products/create";
import ReadProduct from "./screens/products/read";

// Transactions
import Transactions from "./screens/transactions";
import SingleTransaction from "./screens/transactions/update";
import CreateTransaction from "./screens/transactions/create";
import ReadTransaction from "./screens/transactions/read";
// Purchases
import Purchases from "./screens/purchases";
import SinglePurchase from "./screens/purchases/update";
import CreatePurchase from "./screens/purchases/create";
import ReadPurchase from "./screens/purchases/read";

// Entries
import Entries from "./screens/entries";
import SingleEntry from "./screens/entries/update";
import CreateEntry from "./screens/entries/create";
import ReadEntry from "./screens/entries/read";

//Profile
import MyProfile from "./screens/profile/myprofile";

//Organisation
import RegisterOrganisation from "./screens/organisation/create";
import MyOrganisation from "./screens/organisation";
import Orders from "./screens/orders";

/* Components */
import Header from "./components/layout/header/header.js";
import Footer from "./components/layout/footer/footer.js";

function App() {
  /* 
    Roles:
    1. admin
    2. owner
    3. user
    4. viewer
  */

  const aou = ["admin", "owner", "user"];
  const ao = ["admin", "owner"];
  const paid = "paid";
  const free = "free";

  const [role, setRole] = useState(
    localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).role : ""
  );

  const [permissions, setPermissions] = useState(
    localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).permissions : {}
  );

  const [subscription, setSubscription] = useState(
    JSON.parse(localStorage.getItem("organisationInfo"))
      ? JSON.parse(localStorage.getItem("organisationInfo")).subscription
      : ""
  );

  const checkSubscription = (perm, subscription) => {
    if (perm === paid && new Date(Date.now()) > new Date(subscription)) return false;
    return true;
  };

  const allow = (section, page, element, perm = free) => {
    if (checkSubscription(perm, subscription)) {
      if (permissions && permissions[section] && permissions[section][page]) return element;
      if (role === "owner") return element;
    }

    return <Navigate to="/dashboard" />;
  };

  const _allow = (acceptedRoles, element, perm = free) => {
    if (checkSubscription(perm, subscription)) {
      if (acceptedRoles.includes(role)) return element;
    }

    return <Navigate to="/dashboard" />;
  };

  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          {/* Auth */}
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {/* Password */}
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />

          {/* Dashboard */}
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Profile */}
          <Route path="/myprofile" element={<MyProfile />} />

          {/* Members */}
          <Route path="/members" element={allow("members", "read", <Members />, paid)} />
          <Route
            path="/members/consumptions"
            element={allow("members", "read", <MembersConsumptions />, paid)}
          />
          <Route path="/members/:id" element={allow("members", "read", <ReadMember />, paid)} />
          <Route
            path="/members/create"
            element={allow("members", "create", <CreateMember />, paid)}
          />
          <Route
            path="/members/edit/:id"
            element={allow("members", "update", <SingleMember />, paid)}
          />

          {/* Suppliers */}
          <Route path="/suppliers" element={allow("suppliers", "read", <Suppliers />, paid)} />
          <Route
            path="/suppliers/:id"
            element={allow("suppliers", "read", <ReadSupplier />, paid)}
          />
          <Route
            path="/suppliers/create"
            element={allow("suppliers", "create", <CreateSupplier />, paid)}
          />
          <Route
            path="/suppliers/edit/:id"
            element={allow("suppliers", "update", <SingleSupplier />, paid)}
          />

          {/* Entries */}
          <Route path="/entries" element={allow("entries", "read", <Entries />, paid)} />
          <Route path="/entries/:id" element={allow("entries", "read", <ReadEntry />, paid)} />
          <Route
            path="/entries/create"
            element={allow("entries", "create", <CreateEntry />, paid)}
          />
          <Route
            path="/entries/edit/:id"
            element={allow("entries", "update", <SingleEntry />, paid)}
          />

          {/* Products */}
          <Route path="/products" element={allow("products", "read", <Products />, paid)} />
          <Route path="/products/:id" element={allow("products", "read", <ReadProduct />, paid)} />
          <Route
            path="/products/create"
            element={allow("products", "create", <CreateProduct />, paid)}
          />
          <Route
            path="/products/edit/:id"
            element={allow("products", "update", <SingleProduct />, paid)}
          />

          {/* Transactions */}
          <Route
            path="/transactions"
            element={allow("transactions", "read", <Transactions />, paid)}
          />
          <Route
            path="/transactions/create"
            element={allow("transactions", "create", <CreateTransaction />, paid)}
          />
          <Route
            path="/transactions/:id"
            element={allow("transactions", "read", <ReadTransaction />, paid)}
          />
          <Route
            path="/transactions/edit/:id"
            element={allow("transactions", "update", <SingleTransaction />, paid)}
          />

          {/* Purchases */}
          <Route path="/purchases" element={allow("purchases", "read", <Purchases />, paid)} />
          <Route
            path="/purchases/:id"
            element={allow("purchases", "read", <ReadPurchase />, paid)}
          />
          <Route
            path="/purchases/create"
            element={allow("purchases", "create", <CreatePurchase />, paid)}
          />
          <Route
            path="/purchases/edit/:id"
            element={allow("purchases", "update", <SinglePurchase />, paid)}
          />

          {/* Organisation */}
          <Route
            path="/myorganisation"
            element={allow("organisation", "read", <MyOrganisation />)}
          />
          <Route
            path="/organisation/create"
            element={allow("organisation", "create", <RegisterOrganisation />)}
          />
          <Route
            path="/organisation/orders"
            element={allow("organisation", "update", <Orders />)}
          />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
