import {
  MEMBER_LIST_REQUEST,
  MEMBER_LIST_SUCCESS,
  MEMBER_LIST_FAILURE,
  MEMBER_INDIVIDUAL_REQUEST,
  MEMBER_INDIVIDUAL_SUCCESS,
  MEMBER_INDIVIDUAL_FAILURE,
  MEMBER_CREATE_REQUEST,
  MEMBER_CREATE_SUCCESS,
  MEMBER_CREATE_FAILURE,
  MEMBER_UPDATE_REQUEST,
  MEMBER_UPDATE_SUCCESS,
  MEMBER_UPDATE_FAILURE,
  MEMBER_DELETE_REQUEST,
  MEMBER_DELETE_SUCCESS,
  MEMBER_DELETE_FAILURE,
} from "../constants/members";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listMembers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBER_LIST_REQUEST });
    const { data } = await axios.get("/api/members", apiConfig);
    dispatch({ type: MEMBER_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: MEMBER_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getMemberById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBER_INDIVIDUAL_REQUEST });
    const { data } = await axios.get(`/api/members/${id}`, apiConfig);
    dispatch({ type: MEMBER_INDIVIDUAL_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: MEMBER_INDIVIDUAL_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createMemberAction =
  (
    membershipId,
    nickname,
    referrer,
    consumerType,
    firstName,
    lastName,
    email,
    phone,
    address,
    city,
    gender,
    dni,
    dniFront,
    dniBack,
    birthDate,
    membershipDate,
    monthlyConsumptionForecast,
    image,
    contract
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MEMBER_CREATE_REQUEST });
      const { data } = await axios.post(
        "/api/members/create",
        {
          membershipId,
          nickname,
          referrer,
          consumerType,
          firstName,
          lastName,
          email,
          phone,
          address,
          city,
          gender,
          dni,
          dniFront,
          dniBack,
          birthDate,
          membershipDate,
          monthlyConsumptionForecast,
          image,
          contract,
        },
        apiConfig
      );

      dispatch({ type: MEMBER_CREATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: MEMBER_CREATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const updateMemberAction =
  (
    id,
    membershipId,
    nickname,
    referrer,
    consumerType,
    firstName,
    lastName,
    email,
    phone,
    address,
    city,
    gender,
    dni,
    dniFront,
    dniBack,
    birthDate,
    membershipDate,
    monthlyConsumptionForecast,
    image,
    contract
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MEMBER_UPDATE_REQUEST });

      const { data } = await axios.put(
        `/api/members/${id}`,
        {
          membershipId,
          nickname,
          referrer,
          consumerType,
          firstName,
          lastName,
          email,
          phone,
          address,
          city,
          gender,
          dni,
          dniFront,
          dniBack,
          birthDate,
          membershipDate,
          monthlyConsumptionForecast,
          image,
          contract,
        },
        apiConfig
      );

      dispatch({ type: MEMBER_UPDATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: MEMBER_UPDATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const deleteMemberAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBER_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/members/${id}`, apiConfig);

    dispatch({ type: MEMBER_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: MEMBER_DELETE_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
