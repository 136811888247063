import {
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_LIST_FAILURE,
  PURCHASE_CREATE_REQUEST,
  PURCHASE_CREATE_SUCCESS,
  PURCHASE_CREATE_FAILURE,
  PURCHASE_UPDATE_REQUEST,
  PURCHASE_UPDATE_SUCCESS,
  PURCHASE_UPDATE_FAILURE,
  PURCHASE_DELETE_REQUEST,
  PURCHASE_DELETE_SUCCESS,
  PURCHASE_DELETE_FAILURE,
} from "../constants/purchases";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listPurchases = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PURCHASE_LIST_REQUEST });

    const { data } = await axios.get("/api/purchases", apiConfig);

    dispatch({ type: PURCHASE_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PURCHASE_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getPurchaseById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PURCHASE_LIST_REQUEST });

    const { data } = await axios.get(`/api/purchases/${id}`, apiConfig);

    dispatch({ type: PURCHASE_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PURCHASE_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createPurchaseAction =
  (productsArray, date, supplier, bill) => async (dispatch, getState) => {
    try {
      dispatch({ type: PURCHASE_CREATE_REQUEST });

      const { data } = await axios.post(
        "/api/purchases/create",
        {
          productsArray,
          date,
          supplier,
          bill,
        },
        apiConfig
      );

      dispatch({ type: PURCHASE_CREATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: PURCHASE_CREATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const updatePurchaseAction =
  (id, productsArray, date, supplier, bill) => async (dispatch, getState) => {
    try {
      dispatch({ type: PURCHASE_UPDATE_REQUEST });

      const { data } = await axios.put(
        `/api/purchases/${id}`,
        {
          productsArray,
          date,
          supplier,
          bill,
        },
        apiConfig
      );

      dispatch({ type: PURCHASE_UPDATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: PURCHASE_UPDATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const deletePurchaseAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PURCHASE_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/purchases/${id}`, apiConfig);

    dispatch({ type: PURCHASE_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PURCHASE_DELETE_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
