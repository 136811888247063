import {
  MEMBER_LIST_REQUEST,
  MEMBER_LIST_SUCCESS,
  MEMBER_LIST_FAILURE,
  MEMBER_INDIVIDUAL_REQUEST,
  MEMBER_INDIVIDUAL_SUCCESS,
  MEMBER_INDIVIDUAL_FAILURE,
  MEMBER_CREATE_REQUEST,
  MEMBER_CREATE_SUCCESS,
  MEMBER_CREATE_FAILURE,
  MEMBER_UPDATE_REQUEST,
  MEMBER_UPDATE_SUCCESS,
  MEMBER_UPDATE_FAILURE,
  MEMBER_DELETE_REQUEST,
  MEMBER_DELETE_SUCCESS,
  MEMBER_DELETE_FAILURE,
} from "../constants/members";

export const membersReducer = (state = { members: [] }, action) => {
  switch (action.type) {
    case MEMBER_LIST_REQUEST:
      return { loading: true };
    case MEMBER_LIST_SUCCESS:
      return { loading: false, members: action.payload.members };
    case MEMBER_LIST_FAILURE:
      return { loading: false, error: action.payload };
    case MEMBER_INDIVIDUAL_REQUEST:
      return { loading: true };
    case MEMBER_INDIVIDUAL_SUCCESS:
      return {
        ...state,
        loading: false,
        member: action.payload.member,
      };
    case MEMBER_INDIVIDUAL_FAILURE:
      return { loading: false, error: action.payload };
    case MEMBER_CREATE_REQUEST:
      return { loading: true };
    case MEMBER_CREATE_SUCCESS:
      return { loading: false, success: true };
    case MEMBER_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case MEMBER_UPDATE_REQUEST:
      return { loading: true };
    case MEMBER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case MEMBER_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case MEMBER_DELETE_REQUEST:
      return { loading: true };
    case MEMBER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MEMBER_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
