import { React, useState, useEffect } from "react";

import { Card, Col, Row, Table, Form, Modal, Button } from "react-bootstrap";
import placeholder from "../../assets/user_placeholder.png";
import { permissionsModelFalse, permissionsModelTrue } from "./../../utils/permissionsModel";
import { useDispatch, useSelector } from "react-redux";
import { createUser, updateUser } from "redux/actions/organisations";

export default (props) => {
  const { type, user, title, show, onHide } = props;
  const [username, setUsername] = useState(user ? user.username : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [permissions, setPermissions] = useState(user ? user.permissions : permissionsModelFalse);

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo, success, error, loading } = organisation;

  const dispatch = useDispatch();

  const hideModal = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    onHide();
  };

  const submitHandler = async (e) => {
    if (type === "add") {
      dispatch(
        createUser(username, email, password, confirmPassword, organisationInfo._id, permissions)
      );
    } else {
      dispatch(updateUser(user.id, username, email, permissions));
    }
    hideModal();
  };

  const checkPermission = (section, permission, e) => {
    const { checked } = e.target;
    if (checked) {
      if (permission !== "read") {
        setPermissions({
          ...permissions,
          [section]: {
            ...permissions[section],
            [permission]: checked,
            read: checked,
          },
        });
      } else {
        setPermissions({
          ...permissions,
          [section]: {
            ...permissions[section],
            [permission]: checked,
          },
        });
      }
    } else {
      setPermissions({
        ...permissions,
        [section]: {
          ...permissions[section],
          [permission]: checked,
        },
      });
    }
  };

  return (
    <Modal show={true} onHide={() => hideModal()} size="xl" centered keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            {/* Username */}
            <Col md="6">
              <Form.Group className="mb-3" controlId="formBasicUsername">
                <Form.Label>Nombre de Usuario</Form.Label>
                <Form.Control
                  type="username"
                  placeholder="Introduce Nombre de Usuario"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  required
                />
              </Form.Group>
            </Col>
            {/* Email */}
            <Col md="6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Introduce Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </Form.Group>
            </Col>
            {type === "add" && (
              <>
                {/* Password */}
                <Col md="6">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Introduce Contraseña"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                    />
                  </Form.Group>
                </Col>
                {/* Confirm Password */}
                <Col md="6">
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Confirmar Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirmar Contraseña"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
          <Row>
            {/* Check All Button */}
            <Col md="12" className="align-items-end justify-content-end d-flex">
              <Button
                variant="outline-primary"
                className="btn-sm m-2"
                onClick={() => setPermissions(permissionsModelTrue)}
              >
                Marcar Todos
              </Button>
              <Button
                variant="outline-danger"
                className="btn-sm m-2"
                onClick={() => setPermissions(permissionsModelFalse)}
              >
                Quitar Todos
              </Button>
            </Col>
          </Row>

          <Row>
            {/* Permissions */}
            <Col md="12">
              <Table className="">
                <thead>
                  <tr>
                    <th>Permisos</th>
                    <th>Ver</th>
                    <th>Crear</th>
                    <th>Editar</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Organización */}
                  <tr>
                    <td>Organizacion</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("organisation", "read", e)}
                        checked={
                          permissions && permissions.organisation && permissions.organisation.read
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("organisation", "create", e)}
                        checked={
                          permissions && permissions.organisation && permissions.organisation.create
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("organisation", "update", e)}
                        checked={
                          permissions && permissions.organisation && permissions.organisation.update
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("organisation", "delete", e)}
                        checked={
                          permissions && permissions.organisation && permissions.organisation.delete
                        }
                      />
                    </td>
                  </tr>
                  {/* Socios */}
                  <tr>
                    <td>Miembros</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("members", "read", e)}
                        checked={permissions && permissions.members && permissions.members.read}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("members", "create", e)}
                        checked={permissions && permissions.members && permissions.members.create}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("members", "update", e)}
                        checked={permissions && permissions.members && permissions.members.update}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("members", "delete", e)}
                        checked={permissions && permissions.members && permissions.members.delete}
                      />
                    </td>
                  </tr>
                  {/* Entradas */}
                  <tr>
                    <td>Entradas</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("entries", "read", e)}
                        checked={permissions && permissions.entries && permissions.entries.read}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("entries", "create", e)}
                        checked={permissions && permissions.entries && permissions.entries.create}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("entries", "update", e)}
                        checked={permissions && permissions.entries && permissions.entries.update}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("entries", "delete", e)}
                        checked={permissions && permissions.entries && permissions.entries.delete}
                      />
                    </td>
                  </tr>
                  {/* Productos */}
                  <tr>
                    <td>Productos</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("products", "read", e)}
                        checked={permissions && permissions.products && permissions.products.read}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("products", "create", e)}
                        checked={permissions && permissions.products && permissions.products.create}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("products", "update", e)}
                        checked={permissions && permissions.products && permissions.products.update}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("products", "delete", e)}
                        checked={permissions && permissions.products && permissions.products.delete}
                      />
                    </td>
                  </tr>
                  {/* Transacciones */}
                  <tr>
                    <td>Transacciones</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("transactions", "read", e)}
                        checked={
                          permissions && permissions.transactions && permissions.transactions.read
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("transactions", "create", e)}
                        checked={
                          permissions && permissions.transactions && permissions.transactions.create
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("transactions", "update", e)}
                        checked={
                          permissions && permissions.transactions && permissions.transactions.update
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("transactions", "delete", e)}
                        checked={
                          permissions && permissions.transactions && permissions.transactions.delete
                        }
                      />
                    </td>
                  </tr>
                  {/* Compras */}
                  <tr>
                    <td>Compras</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("purchases", "read", e)}
                        checked={permissions && permissions.purchases && permissions.purchases.read}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("purchases", "create", e)}
                        checked={
                          permissions && permissions.purchases && permissions.purchases.create
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("purchases", "update", e)}
                        checked={
                          permissions && permissions.purchases && permissions.purchases.update
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("purchases", "delete", e)}
                        checked={
                          permissions && permissions.purchases && permissions.purchases.delete
                        }
                      />
                    </td>
                  </tr>
                  {/* Proveedores */}
                  <tr>
                    <td>Proveedores</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("providers", "read", e)}
                        checked={permissions && permissions.suppliers && permissions.suppliers.read}
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("suppliers", "create", e)}
                        checked={
                          permissions && permissions.suppliers && permissions.suppliers.create
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("suppliers", "update", e)}
                        checked={
                          permissions && permissions.suppliers && permissions.suppliers.update
                        }
                      />
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => checkPermission("suppliers", "delete", e)}
                        checked={
                          permissions && permissions.suppliers && permissions.suppliers.delete
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            hideModal();
          }}
        >
          Cancelar
        </Button>
        <Button variant="primary" onClick={(e) => submitHandler(e)}>
          {type === "add" ? "Crear" : "Actualizar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
