import React from "react";
import { Form } from "react-bootstrap";
import { Hint, Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

const MemberSelect = (props) => {
  const { members, member, setMember, disabled } = props;
  return !disabled ? (
    <Typeahead
      clearButton
      id="floating-label-example"
      onChange={(selected) => {
        setMember(selected[0]);
      }}
      options={
        members
          ? members.map((member) => {
              return {
                id: member.membershipId,
                label: member.membershipId + " - " + member.firstName + " " + member.lastName,
              };
            })
          : []
      }
      placeholder="Selecciona un usuario"
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
        return (
          <Hint>
            <Form.Control
              {...inputProps}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
              }}
            />
          </Hint>
        );
      }}
      selected={member ? [member] : []}
    />
  ) : (
    <Form.Control type="text" value={member[0].label} disabled />
  );
};

export default MemberSelect;
