import { Container, Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./mainScreen.css";

const MainScreen = ({ title, children, button }) => {
  return (
    <div className="mainback">
      <Container fluid className="mx-4">
        <Row>
          <Row>
            <Col md={3} className="d-flex align-items-center justify-content-start">
              {title && <h3>{title}</h3>}
            </Col>
            <Col md={9} className="d-flex justify-content-end">
              {button && button}
            </Col>
          </Row>
          <hr />
          {children}
        </Row>
      </Container>
    </div>
  );
};

export default MainScreen;
