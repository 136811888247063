// Import components from react-bootstrap
import { Container, Row, Button } from "react-bootstrap";
import { useEffect } from "react";
import "./landingPage.css";

const LandingPage = () => {
  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      window.location.href = "/dashboard";
    }
  }, []);

  return (
    <div className="">
      <Container className="mt-5">
        <Row className="mt-5 d-flex align-items-center justify-content-center">
          <div className="t-center">
            <h1>Admin Cannabis</h1>
            <p>Bienvenido a la aplicación de administración de Admin Cannabis.</p>
          </div>
          <div className="buttonContainer">
            <a href="/login">
              <Button size="lg" className="landingButton px-5" variant="primary">
                Login
              </Button>
            </a>
            {/*             <a href="/register">
              <Button size="lg" className="landingButton">
                Register
              </Button>
            </a> */}
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default LandingPage;
