import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Col, Row, Card, Table } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import ProductSelect from "components/productSelect/supplierProductSelect";

import { listProducts } from "redux/actions/products";
import { listSupplierById } from "redux/actions/suppliers";

import SupplierCard from "components/supplierCard";

export default () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [image, setImage] = useState("");
  const [minPurchase, setMinPurchase] = useState("");
  const [productsArray, setProductsArray] = useState([]);

  const { id } = useParams();
  const dispatch = useDispatch();

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { suppliers, loading, error, success } = suppliersReducer;

  const productsReducer = useSelector((state) => state.products);
  const { products } = productsReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const fetchData = async () => {
    setName(suppliers.supplier.name);
    setEmail(suppliers.supplier.email);
    setPhone(suppliers.supplier.phone);
    setAddress(suppliers.supplier.address);
    setCity(suppliers.supplier.city);
    setImage(suppliers.supplier.image);
    setMinPurchase(suppliers.supplier.minPurchase);
    setProductsArray(suppliers.supplier.products);
  };

  useEffect(() => {
    if (!userInfo) window.location.href = "/";
    dispatch(listSupplierById(id));
    dispatch(listProducts());
  }, [userInfo, success]);

  useEffect(() => {
    if (suppliers && suppliers.supplier) fetchData();
  }, [suppliers]);

  if (loading) {
    return (
      <MainScreen title="Editar Proveedor">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Visualizar Proveedor">
        <form className="p-0">
          <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
            <SupplierCard
              name={name}
              email={email}
              phone={phone}
              address={address}
              city={city}
              minPurchase={minPurchase}
              image={image}
              type="read"
              setName={setName}
              setEmail={setEmail}
              setPhone={setPhone}
              setAddress={setAddress}
              setCity={setCity}
              setMinPurchase={setMinPurchase}
              setImage={setImage}
            />
            <Row>
              <Col md={12}>
                <Table striped="columns">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Descripción</th>
                      <th>Peso</th>
                      <th>Precio Venta</th>
                      <th>Precio Compra</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Products */}
                    {productsArray.map((product, index) => {
                      if (
                        products &&
                        products.products &&
                        product &&
                        product._id &&
                        products.products.find((p) => p._id === product._id)
                      ) {
                        return (
                          <tr key={index} className="align-middle">
                            <td className="text-start">
                              {products && products.products && product && (
                                <ProductSelect
                                  products={products}
                                  product={product}
                                  productsArray={productsArray}
                                  setProductsArray={setProductsArray}
                                  index={index}
                                  disabled
                                />
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                value={
                                  products.products.find((p) => p._id === product._id).description
                                }
                                disabled
                                className="customInput"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={products.products.find((p) => p._id === product._id).weight}
                                disabled
                                className="customInput"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={products.products.find((p) => p._id === product._id).price}
                                disabled
                                className="customInput"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={product.price}
                                onChange={(e) => {
                                  let newArray = [...productsArray];
                                  newArray[index].price = e.target.value;
                                  setProductsArray(newArray);
                                }}
                                disabled
                                className="customInput"
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>

          {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
          {success && <ErrorMessage variant="success">Supplier Updated Successfully</ErrorMessage>}
        </form>
      </MainScreen>
    );
  }
};
