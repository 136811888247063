import React from "react";
import { Card, Col, Row, Table, Button } from "react-bootstrap";
import placeholder from "assets/user_placeholder.png";
/* import css */
import "./index.css";

const toBase64 = (file) => {
  if (!file) return "";

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const SupplierCard = (props) => {
  const {
    type,
    name,
    email,
    phone,
    address,
    city,
    image,
    minPurchase,
    setName,
    setEmail,
    setPhone,
    setAddress,
    setCity,
    setMinPurchase,
    setImage,
  } = props;

  const read = "read";

  return (
    <Row className="align-items-center">
      <Col md={3}>
        <img
          src={image ? image : placeholder}
          alt="supplierImage"
          className="img-fluid rounded-circle shadow supplierImage"
          style={{ height: "300px", width: "300px" }}
          onClick={(e) => {
            /* click memberImageInput */
            document.getElementById("supplierImageInput").click();
          }}
        />
        <input
          className="d-none"
          type="file"
          id="supplierImageInput"
          accept="image/*"
          onChange={async (e) => {
            if (e.target.files[0].size > 5000000) {
              alert("El tamaño de la imagen no puede ser mayor a 5MB");
              return;
            }
            setImage(await toBase64(e.target.files[0]));
          }}
        ></input>
      </Col>
      <Col md={9}>
        <Row>
          <Col md={6}>
            <Table striped="columns">
              <tbody>
                {/* Name */}
                <tr>
                  <th>Nombre</th>
                  {type === read && <td className="customValue">{name}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
                {/* Address */}
                <tr>
                  <th>Dirección</th>
                  {type === read && <td className="customValue">{address}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
                {/* City */}
                <tr>
                  <th>Ciudad</th>
                  {type === read && <td className="customValue">{city}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md={6}>
            <Table striped="columns">
              <tbody>
                {/* Phone */}
                <tr>
                  <th>Teléfono</th>
                  {type === read && <td className="customValue">{phone}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
                {/* Email */}
                <tr>
                  <th>Email</th>
                  {type === read && <td className="customValue">{email}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
                {/* Minimum Purchase */}
                <tr>
                  <th>Compra mínima</th>
                  {type === read && <td className="customValue">{minPurchase}</td>}
                  {type !== read && (
                    <td>
                      <input
                        type="number"
                        value={minPurchase}
                        onChange={(e) => setMinPurchase(e.target.value)}
                        className="customInput"
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SupplierCard;
