import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { createTransactionAction } from "redux/actions/transactions";
import TopazSignature from "components/custom/topazSignature";
import moment from "moment";

import TxTable from "components/txTable";
import MemberSelect from "components/memberSelect";

import {
  handleSig,
  handleSigCapture,
  addNewProduct,
  drawSignature,
  resetHandler,
  removeProduct,
} from "../helpers/functions";

import { listener, startTablet } from "utils/SigWebTablet";

import { listProducts } from "redux/actions/products";
import { listMembers } from "redux/actions/members";

export default () => {
  const [total, setTotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [member, setMember] = useState("");
  const [signature, setSignature] = useState("");
  const [date, setDate] = useState("");
  const [sigCaptured, setSigCaptured] = useState(null);
  const [productsArray, setProductsArray] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "RESET_TRANSACTIONS" });
  }, [dispatch]);

  /* TOPAZ */
  listener();

  /* Redux State */
  const productsReducer = useSelector((state) => state.products);
  const { products } = productsReducer;

  const transactionsReducer = useSelector((state) => state.transactions);
  const { loading, error, success } = transactionsReducer;

  const membersReducer = useSelector((state) => state.members);
  const { members } = membersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const userId = searchParams.get("userId");
  const productId = searchParams.get("productId");

  /* useEffect */
  useEffect(() => {
    if (!userInfo) window.location.href = "/";

    dispatch(listProducts());
    dispatch(listMembers());
    if (success)
      resetHandler(setProductsArray, setTotal, setMember, setSignature, setDate, setTotalWeight);
    setDate(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
  }, [userInfo, success]);

  useEffect(() => {
    if (userId && members)
      setMember({
        id: userId,
        label:
          members.find((member) => member.membershipId === userId).membershipId +
          " - " +
          members.find((member) => member.membershipId === userId).firstName +
          " " +
          members.find((member) => member.membershipId === userId).lastName,
      });
    if (products && products.products && productId) {
      setProductsArray([
        {
          _id: productId,
          quantity: 1,
          stamps: {
            price: products.products.find((product) => product._id === productId).price,
            weight: products.products.find((product) => product._id === productId).weight,
          },
        },
      ]);
    }
  }, [products, productId, userId]);

  if (loading) {
    return (
      <MainScreen title="Crear Transacción">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Crear Transacción">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <p className="my-3 text-muted">
            Fecha Creación - {new Date().toLocaleDateString("es-ES")}
          </p>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(createTransactionAction(member.id, productsArray, signature, date));
            }}
          >
            <Row>
              {/* Socio */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-member">
                  <Form.Label>Socio</Form.Label>
                  {members && members.length ? (
                    <MemberSelect members={members} member={member} setMember={setMember} />
                  ) : (
                    <Form.Control type="text" placeholder="No hay socios" disabled />
                  )}
                </Form.Group>
              </Col>
              {/* Fecha */}
              <Col md={6}>
                <Form.Group className="mb-3" controlId="form-date">
                  <Form.Label>Fecha / Hora Transacción</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <p className="my-3 text-muted">Productos:</p>
            <TxTable
              productsArray={productsArray}
              setProductsArray={setProductsArray}
              products={products}
              type="create"
              screen="transaction"
            />
            <p className="my-3 text-muted">Firma :</p>
            <Row>
              <Col md={12} className="border m-3">
                <canvas id="cnv" width="500" height="100"></canvas>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  className="mr-1"
                  variant="warning"
                  onClick={() => {
                    startTablet();
                  }}
                >
                  Firmar
                </Button>
                <Button
                  variant="success"
                  className="m-1"
                  onClick={() => {
                    handleSigCapture(setSigCaptured, setSignature);
                  }}
                >
                  Completar
                </Button>
                {signature.length > 0 && (
                  <Button variant="primary" type="submit" className="m-1">
                    Crear
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
        {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
        {success && <ErrorMessage variant="success">Transaction Created Successfully</ErrorMessage>}
      </MainScreen>
    );
  }
};
