import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listMembers, deleteMemberAction } from "redux/actions/members";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid } from "components/custom/stripedDataGrid.js";

const currencyFormatter = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

/* Format value grams */
const gramsFormatter = new Intl.NumberFormat("es-ES", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export default () => {
  const dispatch = useDispatch();

  const membersReducer = useSelector((state) => state.members);
  const { loading, error, members, success } = membersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteMemberAction(id));
      window.location.href = "/members";
    }
  };

  const defineDataGrid = async (members) => {
    const rows = [];
    await members.forEach((member) => {
      rows.push({
        id: member._id,
        membershipId: member.membershipId,
        nickname: member.nickname,
        referrer: member.referrer,
        referees: member.referees.length,
        consumerType: member.consumerType,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        phone: member.phone,
        dni: member.dni,
        address: member.address,
        birthDate: new Date(member.birthDate).toISOString().substring(0, 10),
        membershipDate: new Date(member.membershipDate).toISOString().substring(0, 10),
        monthlyConsumptionForecast: parseInt(member.monthlyConsumptionForecast),
        consumptionActual: parseInt(member.totalWeightConsumed),
        dniFront: member.dniFront,
        dniBack: member.dniBack,
        contract: member.contract,
      });
    });

    setRowsDefs(rows);
    setColumnDefs([
      {
        headerName: "Interacciones",
        field: "interactions",
        width: 250,
        renderCell: (rowData) => {
          console.log(rowData);
          return (
            <div>
              {userInfo && userInfo.permissions && userInfo.permissions["entries"]["create"] && (
                <Link to={`/entries/create?userId=${rowData.row.membershipId}`}>
                  <Button className="btn btn-sm m-2" variant="primary">
                    Nueva Entrada
                  </Button>
                </Link>
              )}
              {userInfo &&
                userInfo.permissions &&
                userInfo.permissions["transactions"]["create"] && (
                  <Link to={`/transactions/create?userId=${rowData.row.membershipId}`}>
                    <Button className="btn btn-sm m-2" variant="secondary">
                      Nueva TX
                    </Button>
                  </Link>
                )}
            </div>
          );
        },
      },
      {
        headerName: "ID Socio",
        field: "membershipId",
        width: 150,
        sortable: true,
        filter: true,
      },
      { headerName: "Nickname", field: "nickname", width: 150, sortable: true, filter: true },
      { headerName: "Avalista", field: "referrer", width: 150, sortable: true, filter: true },
      { headerName: "Avalados", field: "referees", width: 150, sortable: true, filter: true },
      {
        headerName: "Tipo de Consumidor",
        field: "consumerType",
        width: 150,
        sortable: true,
        filter: true,
      },

      {
        headerName: "Nombre",
        field: "firstName",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Apellido",
        field: "lastName",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Dirección",
        field: "address",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Email",
        field: "email",
        width: 150,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Teléfono",
        field: "phone",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Dni",
        field: "dni",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Fecha de nacimiento",
        field: "birthDate",
        width: 100,
        sortable: true,
        filter: true,
        type: "date",
      },
      {
        headerName: "Fecha de afiliación",
        field: "membershipDate",
        width: 100,
        sortable: true,
        filter: true,
        type: "date",
      },
      {
        headerName: "Previsión Anual",
        field: "monthlyConsumptionForecast",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: ({ value }) => {
          return gramsFormatter.format(value) + " g";
        },
      },
      {
        headerName: "Consumo actual",
        field: "consumptionActual",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: ({ value }) => {
          return gramsFormatter.format(value) + " g";
        },
      },
      {
        headerName: "Dni Front",
        field: "dniFront",
        width: 120,

        renderCell: (rowData) => {
          return rowData.row.dniFront ? (
            <a href={rowData.row.dniFront} target="_blank">
              <Button className="btn btn-sm m-2" variant="success" disabled>
                Disponible
              </Button>
            </a>
          ) : (
            <Button className="btn btn-sm m-2" variant="danger" disabled>
              No Disponible
            </Button>
          );
        },
      },
      {
        headerName: "Dni Back",
        field: "dniBack",
        width: 120,

        renderCell: (rowData) => {
          return rowData.row.dniBack ? (
            <a href={rowData.row.dniBack} target="_blank">
              <Button className="btn btn-sm m-2" variant="success" disabled>
                Disponible
              </Button>
            </a>
          ) : (
            <Button className="btn btn-sm m-2" variant="danger" disabled>
              No Disponible
            </Button>
          );
        },
      },
      {
        headerName: "Contrato",
        field: "contract",
        width: 120,

        renderCell: (rowData) => {
          return rowData.row.contract ? (
            <a href={rowData.row.contract} target="_blank">
              <Button className="btn btn-sm m-2" variant="success" disabled>
                Disponible
              </Button>
            </a>
          ) : (
            <Button className="btn btn-sm m-2" variant="danger" disabled>
              No Disponible
            </Button>
          );
        },
      },
      {
        headerName: "Acciones",
        field: "actions",
        width: 200,
        renderCell: (rowData) => {
          return (
            <div>
              <Link to={`/members/${rowData.id}`}>
                <Button className="btn btn-sm m-2" variant="success">
                  Ver
                </Button>
              </Link>{" "}
              {userInfo && userInfo.permissions && userInfo.permissions["members"]["update"] && (
                <Link to={`/members/edit/${rowData.id}`}>
                  <Button className="btn btn-sm m-2" variant="warning">
                    Editar
                  </Button>
                </Link>
              )}
              {userInfo && userInfo.permissions && userInfo.permissions["members"]["delete"] && (
                <Button
                  variant="danger"
                  onClick={() => deleteHandler(rowData.id)}
                  className=" btn btn-sm m-2"
                >
                  Eliminar
                </Button>
              )}
            </div>
          );
        },
      },
    ]);
  };

  const customButton = () => {
    if (organisationInfo) {
      return (
        <>
          <Link to="/members/consumptions">
            <Button className="m-2" variant="primary">
              Consumos
            </Button>
          </Link>

          <Link to="/members/create">
            <Button className="m-2" variant="success">
              Nuevo Socio
            </Button>
          </Link>
        </>
      );
    } else {
      return (
        <Link to="/organisation/create">
          <Button className="btn btn-sm m-2" variant="success">
            Nueva Organización
          </Button>
        </Link>
      );
    }
  };

  useEffect(() => {
    dispatch(listMembers());
    if (!userInfo) window.location.href = "/login";
  }, [dispatch, success, userInfo]);

  useEffect(() => {
    if (members) {
      defineDataGrid(members);
    }
  }, [members]);

  return (
    <MainScreen
      title={`Socios`}
      button={
        userInfo &&
        userInfo.permissions &&
        userInfo.permissions["members"]["create"] &&
        customButton()
      }
    >
      <Row>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        {members && (
          <div>
            <StripedDataGrid
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              sortModel={[{ field: "membershipId", sort: "asc" }]}
            />
          </div>
        )}
      </Row>
    </MainScreen>
  );
};
