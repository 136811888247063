export const TRANSACTION_LIST_REQUEST = "TRANSACTION_LIST_REQUEST";
export const TRANSACTION_LIST_SUCCESS = "TRANSACTION_LIST_SUCCESS";
export const TRANSACTION_LIST_FAILURE = "TRANSACTION_LIST_FAILURE";

export const TRANSACTION_CREATE_REQUEST = "TRANSACTION_CREATE_REQUEST";
export const TRANSACTION_CREATE_SUCCESS = "TRANSACTION_CREATE_SUCCESS";
export const TRANSACTION_CREATE_FAILURE = "TRANSACTION_CREATE_FAILURE";

export const TRANSACTION_UPDATE_REQUEST = "TRANSACTION_UPDATE_REQUEST";
export const TRANSACTION_UPDATE_SUCCESS = "TRANSACTION_UPDATE_SUCCESS";
export const TRANSACTION_UPDATE_FAILURE = "TRANSACTION_UPDATE_FAILURE";

export const TRANSACTION_DELETE_REQUEST = "TRANSACTION_DELETE_REQUEST";
export const TRANSACTION_DELETE_SUCCESS = "TRANSACTION_DELETE_SUCCESS";
export const TRANSACTION_DELETE_FAILURE = "TRANSACTION_DELETE_FAILURE";

export const RESET_TRANSACTIONS = "RESET_TRANSACTIONS";
