import {
  ENTRY_LIST_REQUEST,
  ENTRY_LIST_SUCCESS,
  ENTRY_LIST_FAILURE,
  ENTRY_CREATE_REQUEST,
  ENTRY_CREATE_SUCCESS,
  ENTRY_CREATE_FAILURE,
  ENTRY_UPDATE_REQUEST,
  ENTRY_UPDATE_SUCCESS,
  ENTRY_UPDATE_FAILURE,
  ENTRY_DELETE_REQUEST,
  ENTRY_DELETE_SUCCESS,
  ENTRY_DELETE_FAILURE,
} from "../constants/entries";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listEntries = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ENTRY_LIST_REQUEST });

    const { data } = await axios.get("/api/entries", apiConfig);

    dispatch({ type: ENTRY_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ENTRY_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getEntryById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENTRY_LIST_REQUEST });

    const { data } = await axios.get(`/api/entries/${id}`, apiConfig);

    dispatch({ type: ENTRY_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ENTRY_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createEntryAction = (memberId, entryDate) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENTRY_CREATE_REQUEST });

    const { data } = await axios.post(
      "/api/entries/create",
      {
        memberId,
        entryDate,
      },
      apiConfig
    );

    dispatch({ type: ENTRY_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ENTRY_CREATE_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const updateEntryAction = (id, memberId, entryDate) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENTRY_UPDATE_REQUEST });

    const { data } = await axios.put(
      `/api/entries/${id}`,
      {
        memberId,
        entryDate,
      },
      apiConfig
    );

    dispatch({ type: ENTRY_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ENTRY_UPDATE_FAILURE,
      payload: err.response && err.response.data ? err.response.data : err.message,
    });
  }
};

export const deleteEntryAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENTRY_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/entries/${id}`, apiConfig);

    dispatch({ type: ENTRY_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ENTRY_DELETE_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
