import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const toBase64 = (file) => {
  if (!file) return "";

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const openDni = (e, b64) => {
  let pdfWindow = window.open("", "_blank");
  pdfWindow.document.write(`<iframe width='100%' height='100%' src=${encodeURI(b64)}></iframe>`);
};

const ImageInput = (props) => {
  const { value, setValue, customRef } = props;
  console.log(customRef);
  return (
    <Row className="d-flex">
      <Col md={3} className="mb-2">
        <input
          id={customRef}
          type="file"
          hidden
          accept="image/png, image/jpeg, image/jpg"
          onChange={async (e) => {
            if (e.target.files[0].size > 5000000) {
              alert("El tamaño de la imagen no puede ser mayor a 5MB");
              return;
            }

            let preview = await toBase64(e.target.files[0]);
            setValue(preview);
          }}
        />
        <Button onClick={() => document.getElementById(customRef).click()}>Browse</Button>
      </Col>
      <Col md={9} className="justify-content-start d-flex">
        {value && (
          <img
            src={value}
            id="preview"
            class="img-thumbnail"
            style={{ height: "100px", cursor: "pointer" }}
            onClick={() => {
              window.open(URL.createObjectURL(value));
            }}
          ></img>
        )}
      </Col>
    </Row>
  );
};

const ImageInput2 = (props) => {
  const { value, setValue, customRef } = props;
  console.log(customRef);
  return (
    <Row
      className="d-flex justify-content-center align-items-center"
      style={{ marginTop: "3px", marginBottom: "3px" }}
    >
      <Col md={6}>
        <input
          id={customRef}
          type="file"
          hidden
          accept="image/png, image/jpeg, image/jpg"
          onChange={async (e) => {
            if (e.target.files[0].size > 5000000) {
              alert("El tamaño de la imagen no puede ser mayor a 5MB");
              return;
            }
            console.log(e.target.files[0]);
            let preview = await toBase64(e.target.files[0]);
            setValue(preview);
          }}
        />
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            document.getElementById(customRef).click();
          }}
        >
          Explorar
        </a>
      </Col>
      <Col md={6}>
        {value ? (
          <a
            href={value}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              openDni(e, value);
            }}
          >
            Ver
          </a>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

const PdfInput = (props) => {
  const { value, setValue, customRef } = props;
  return (
    <Row
      className="d-flex justify-content-center align-items-center"
      style={{ marginTop: "3px", marginBottom: "3px" }}
    >
      <Col md={6}>
        <input
          id={customRef}
          type="file"
          hidden
          accept="application/pdf"
          onChange={async (e) => {
            if (e.target.files[0].size > 5000000) {
              alert("El tamaño del arxivo no puede ser mayor a 5MB");
              return;
            }
            let preview = await toBase64(e.target.files[0]);
            setValue(preview);
          }}
        />
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            document.getElementById(customRef).click();
          }}
        >
          Explorar
        </a>
      </Col>
      <Col md={6}>
        {value ? (
          <a
            href={value}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              openDni(e, value);
            }}
          >
            Ver
          </a>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

export { ImageInput, ImageInput2, PdfInput };
