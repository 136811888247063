export function handleSig() {}

export function handleSigCapture(setSigCaptured, setSignature) {
  var cnv = document.getElementById("cnv");
  let sig = cnv.toDataURL("image/png");
  setSigCaptured(true);
  setSignature(sig);
  console.log(sig);
}

export function addNewProduct(setProductsArray, productsArray) {
  setProductsArray([
    ...productsArray,
    {
      _id: "",
      quantity: "",
      stamps: {
        price: "",
        weight: "",
      },
    },
  ]);
}

export function removeProduct(index, setProductsArray, productsArray) {
  let newArray = [...productsArray];
  newArray.splice(index, 1);
  setProductsArray(newArray);
}

export function updateTotal(productsArray, setTotal) {
  let total = 0;
  productsArray.forEach((product) => {
    total += product.quantity * product.stamps[0].price;
  });
  setTotal(total);
}

export function updateTotalWeight(productsArray, setTotalWeight) {
  let total = 0;
  productsArray.forEach((product) => {
    total += product.quantity * product.stamps.weight;
  });
  setTotalWeight(total);
}

export function resetHandler(
  setProductsArray,
  setTotal,
  setMember,
  setSignature,
  setDate,
  setTotalWeight
) {
  setProductsArray([]);
  setMember("");
  setSignature("");
  setDate("");
  setTotal(0);
  setTotalWeight(0);
  window.location.href = "/transactions";
}

export function drawSignature(signature) {
  let cnv = document.getElementById("cnv");
  let ctx = cnv.getContext("2d");
  var image = new Image();
  image.onload = function () {
    ctx.drawImage(image, 0, 0);
  };
  image.src = signature;
}
