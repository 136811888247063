import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Col, Row, Card, Table } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { getProductById } from "redux/actions/products";
import axios from "axios";

import { listSuppliers } from "redux/actions/suppliers";

import ProductCard from "components/productCard";

export default () => {
  const [ean, setEan] = useState("");
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [date, setDate] = useState("");
  const [image, setImage] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const productsReducer = useSelector((state) => state.products);
  const { products, loading, error, success } = productsReducer;

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { suppliers } = suppliersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    setEan("");
    setCategory("");
    setName("");
    setDescription("");
    setPrice("");
    setDate("");
    setWeight("");
    setImage("");
    window.location.href = "/products";
  };

  const fetchData = async () => {
    setEan(products.product.ean);
    setCategory(products.product.category);
    setName(products.product.name);
    setDescription(products.product.description);
    setPrice(products.product.price);
    setWeight(products.product.weight);
    setDate(products.product.date);
    setImage(products.product.image);
  };

  useEffect(() => {
    if (!userInfo) window.location.href = "/";
    if (success) resetHandler();
    dispatch(listSuppliers());
    dispatch(getProductById(id));
  }, [userInfo, success]);

  useEffect(() => {
    if (products && products.product) fetchData();
  }, [products]);

  if (loading) {
    return (
      <MainScreen title="Visualizar Producto">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Visualizar Producto">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <ProductCard
            ean={ean}
            category={category}
            name={name}
            description={description}
            price={price}
            weight={weight}
            image={image}
            type="read"
          />
        </div>
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Row>
            <Col md={12}>
              <Table striped="columns">
                <thead>
                  <tr>
                    <th>Acciones</th>
                    <th>Proveedor</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Check Suppliers that has actual product */}
                  {suppliers &&
                    suppliers.suppliers &&
                    suppliers.suppliers.map((supplier) => {
                      return supplier.products.map((product) => {
                        if (product && product._id && product._id._id === id) {
                          return (
                            <tr>
                              <td></td>
                              <td>{supplier.name}</td>
                              <td>{product.price}</td>
                            </tr>
                          );
                        }
                      });
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      </MainScreen>
    );
  }
};
