import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listMembers, deleteMemberAction } from "redux/actions/members";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid } from "components/custom/stripedDataGrid.js";

import axios from "axios";

export default () => {
  const [orders, setOrders] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [createdAt, setCreatedAt] = useState("");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo, success, error, loading } = organisation;

  const fetchData = async () => {
    const { data } = await axios
      .get(`/api/organisations/${organisationInfo._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return { data: res.data };
      });

    setCreatedAt(data.organisation.createdAt);
    setSubscription(data.organisation.subscription);

    const { ordersData } = await axios
      .get(`/api/monei/ordersByOrganisation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return { ordersData: res.data.orders };
      });

    setOrders(ordersData);
  };

  const planCardAction = (amount, period) => {
    /* Monei Pay */
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    };
    axios
      .post(
        "/api/monei/payment",
        {
          amount: amount,
          product: period,
          description: `Payment for ${period} plan`,
        },
        config
      )
      .then((res) => {
        console.log(res);
        window.location.href = res.data.payment.nextAction.redirectUrl;
      });
  };

  useEffect(() => {
    dispatch(listMembers());
    if (!userInfo) window.location.href = "/login";
    fetchData();
  }, [dispatch, success, userInfo]);

  return (
    <MainScreen title={`Subscripción y pagos`}>
      <div className="shadow p-3 mb-3 bg-white rounded">
        <Row>
          <Col md={6}>
            <Card className="mb-4 h-100">
              <Card.Header>Subscripción Actual</Card.Header>
              <Card.Body>
                <Row className="m-2">
                  <Col md="6">Estado de la subscripción: </Col>
                  <Col md="6 text-end">
                    <Badge bg="success" className="">
                      Activa
                    </Badge>
                  </Col>
                </Row>
                <Row className="m-2">
                  <Col md="6">Fecha de inicio: </Col>
                  <Col md="6 text-end">
                    <span className="m-2">{new Date(createdAt).toLocaleDateString()}</span>
                  </Col>
                </Row>
                <Row className="m-2">
                  <Col md="6">Fecha de vencimiento: </Col>
                  <Col md="6 text-end">
                    <span className="m-2">{new Date(subscription).toLocaleDateString()}</span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4 h-100">
              <Card.Header>Realizar pago único</Card.Header>
              <Card.Body>
                <Row className="m-2">
                  {/* 1M */}
                  <Col md="4">
                    <Card className="planCard" onClick={() => planCardAction(7000, "1M")}>
                      <Card.Body>
                        <Card.Title className="text-center">1M</Card.Title>
                        <Card.Text className="text-center">
                          <span className="text">70€</span>
                          <br />
                          <small className="text-success">70€ / mes</small>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* 6M */}
                  <Col md="4">
                    <Card className="planCard" onClick={() => planCardAction(30000, "6M")}>
                      <Card.Body>
                        <Card.Title className="text-center">6M</Card.Title>
                        <Card.Text className="text-center">
                          <span className="text">300€</span>
                          <br />
                          <small className="text-success">60€ / mes</small>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* 12M */}
                  <Col md="4">
                    <Card className="planCard" onClick={() => planCardAction(60000, "12M")}>
                      <Card.Body>
                        <Card.Title className="text-center">12M</Card.Title>
                        <Card.Text className="text-center">
                          <span className="text">600€</span>
                          <br />
                          <small className="text-success">50€ / mes</small>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>{" "}
        </Row>
        <Row>
          <Col md={12}>
            <Card className="my-4">
              <Card.Header>Historial de pagos</Card.Header>
              <Card.Body>
                <Table>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Concepto</th>
                      <th>Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders &&
                      orders.length &&
                      orders.map((order) => (
                        <tr>
                          <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                          <td>{order.product}</td>
                          <td>{order.amount / 100}€</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
      {loading && <Loading />}
    </MainScreen>
  );
};
