export const permissionsModelFalse = {
  entries: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  members: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  organisation: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  products: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  purchases: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  suppliers: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  transactions: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
};

export const permissionsModelTrue = {
  entries: {
    read: true,
    update: true,
    delete: true,
    create: true,
  },
  members: {
    read: true,
    update: true,
    delete: true,
    create: true,
  },
  organisation: {
    read: true,
    update: true,
    delete: true,
    create: true,
  },
  products: {
    read: true,
    update: true,
    delete: true,
    create: true,
  },
  purchases: {
    read: true,
    update: true,
    delete: true,
    create: true,
  },
  suppliers: {
    read: true,
    update: true,
    delete: true,
    create: true,
  },
  transactions: {
    read: true,
    update: true,
    delete: true,
    create: true,
  },
};
