import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Col, Row, Card, Table } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { updateProductAction, getProductById } from "redux/actions/products";
import { listSuppliers } from "redux/actions/suppliers";

import axios from "axios";

import ProductCard from "components/productCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

export default () => {
  const [ean, setEan] = useState("");
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [image, setImage] = useState("");
  const [suppliersArray, setSuppliersArray] = useState([]);

  const [date, setDate] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();

  const productsReducer = useSelector((state) => state.products);
  const { products, loading, error, success } = productsReducer;

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { suppliers } = suppliersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    setEan("");
    setCategory("");
    setName("");
    setDescription("");
    setPrice("");
    setDate("");
    setWeight("");
    setSuppliersArray([]);
    setImage("");
    window.location.href = "/products";
  };

  const addSupplierHandler = () => {
    setSuppliersArray([...suppliersArray, { id: "", price: "" }]);
  };

  function removeProduct(index, setSuppliers, suppliersArray) {
    let newArray = [...suppliersArray];
    newArray.splice(index, 1);
    setSuppliersArray(newArray);
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(
      updateProductAction(
        id,
        ean,
        category,
        name,
        description,
        price,
        weight,
        suppliersArray,
        image
      )
    );
  };

  const fetchData = async () => {
    setName(products.product.name);
    setCategory(products.product.category);
    setEan(products.product.ean);
    setDescription(products.product.description);
    setPrice(products.product.price);
    setWeight(products.product.weight);
    setDate(products.product.date);
    setImage(products.product.image);
  };

  useEffect(() => {
    dispatch(getProductById(id));
    dispatch(listSuppliers());
    if (!userInfo) window.location.href = "/";
    if (success) resetHandler();
  }, [dispatch, id, success, userInfo]);

  useEffect(() => {
    if (products && products.product) fetchData();
  }, [products]);

  useEffect(() => {
    setSuppliersArray([]);
    suppliers &&
      suppliers.suppliers &&
      suppliers.suppliers.map((supplier) => {
        return supplier.products.map((product) => {
          if (product && product._id && product._id._id === id) {
            setSuppliersArray([
              ...suppliersArray,
              {
                id: supplier._id,
                price: supplier.products.find(
                  (product) => product && product._id && product._id._id && product._id._id === id
                ).price,
              },
            ]);
          }
        });
      });
  }, [suppliers]);

  if (loading) {
    return (
      <MainScreen title="Editar Producto">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Editar Producto">
        <Form>
          <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
            <ProductCard
              ean={ean}
              category={category}
              name={name}
              description={description}
              price={price}
              weight={weight}
              image={image}
              setEan={setEan}
              setCategory={setCategory}
              setName={setName}
              setDescription={setDescription}
              setPrice={setPrice}
              setWeight={setWeight}
              setImage={setImage}
              type="create"
            />
          </div>
          <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
            <Row>
              <Col md={12}>
                {/* Add Supplier button */}
                <Button variant="success" className="my-3" onClick={addSupplierHandler}>
                  <FontAwesomeIcon icon={faPlus} className="" />
                </Button>
                <Table striped="columns">
                  <thead>
                    {}
                    <tr>
                      <th>Acciones</th>
                      <th>Proveedor</th>
                      <th>Precio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log(suppliersArray)}
                    {suppliersArray.map((supplierItem, index) => (
                      <tr key={index} className="align-middle">
                        <td>
                          <Button
                            variant="danger"
                            className="my-3"
                            onClick={() => removeProduct(index, setSuppliersArray, suppliersArray)}
                          >
                            <FontAwesomeIcon icon={faTrash} className="" />
                          </Button>
                        </td>
                        <td>
                          <Form.Control
                            as="select"
                            value={supplierItem.id}
                            onChange={(e) => {
                              if (suppliersArray.find((p) => p.id === e.target.value))
                                alert("Proveedor ya añadido");
                              else {
                                const updatedSuppliers = [...suppliersArray];
                                updatedSuppliers[index].id = e.target.value;
                                setSuppliersArray(updatedSuppliers);
                              }
                            }}
                          >
                            <option value="">Seleccionar Proveedor</option>
                            {suppliers &&
                              suppliers.suppliers.map((supplier) => (
                                <option key={supplier._id} value={supplier._id}>
                                  {supplier.name}
                                </option>
                              ))}
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            placeholder="Precio"
                            value={supplierItem.price}
                            onChange={(e) => {
                              const updatedSuppliers = [...suppliersArray];
                              updatedSuppliers[index].price = e.target.value;
                              setSuppliersArray(updatedSuppliers);
                            }}
                          ></Form.Control>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
          {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
          {success && <ErrorMessage variant="success"></ErrorMessage>}

          <Button variant="primary" type="submit" onClick={submitHandler}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Guardar
          </Button>
        </Form>
      </MainScreen>
    );
  }
};
