import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listEntries, deleteEntryAction } from "redux/actions/entries";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid, StripedDataGridWithFooter } from "components/custom/stripedDataGrid.js";

export default () => {
  const dispatch = useDispatch();

  const entriesReducer = useSelector((state) => state.entries);
  const { loading, error, entries, success } = entriesReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteEntryAction(id));
      window.location.href = "/entries";
    }
  };

  const defineDataGrid = (entries) => {
    let rows = [];

    entries.entries.forEach((entry) => {
      rows.push({
        id: entry._id,
        membershipId: entry.memberId ? entry.memberId.membershipId : "Unknown / Deleted Account",
        entryDate: new Date(entry.entryDate).toISOString().substring(0, 10),
        entryTime: new Date(entry.entryDate).toTimeString().substring(0, 15),
      });
    });

    setRowsDefs(rows);
    setColumnDefs([
      {
        headerName: "ID Entrada",
        field: "id",
        width: 220,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Fecha",
        field: "entryDate",
        width: 150,
        sortable: true,
        filter: true,
        type: "date",
      },
      {
        headerName: "Hora",
        field: "entryTime",
        width: 200,
        sortable: true,
        filter: true,
        type: "time",
      },
      {
        headerName: "Id Socio",
        field: "membershipId",
        width: 200,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Acciones",
        field: "actions",
        width: 200,
        renderCell: (rowData) => {
          return (
            <div>
              {userInfo && userInfo.permissions && userInfo.permissions["members"]["update"] && (
                <Link to={`/entries/${rowData.id}`}>
                  <Button className="btn btn-sm m-2" variant="success">
                    Ver
                  </Button>
                </Link>
              )}
              {/* {userInfo && userInfo.permissions && userInfo.permissions["members"]["delete"] && (
                <Link to={`/entries/edit/${rowData.id}`}>
                  <Button className="btn btn-sm m-2" variant="warning">
                    Editar
                  </Button>
                </Link>
              )} */}
              <Button
                variant="danger"
                onClick={() => deleteHandler(rowData.id)}
                className=" btn btn-sm m-2"
              >
                Eliminar
              </Button>
            </div>
          );
        },
      },
    ]);
  };

  useEffect(() => {
    dispatch(listEntries());

    if (!userInfo) {
      window.location.href = "/login";
    }
  }, [dispatch, success, userInfo]);

  useEffect(() => {
    if (entries && entries.entries && entries.entries.length) defineDataGrid(entries);
  }, [entries]);

  const customButton = () => {
    if (organisationInfo) {
      return (
        <Link to="/entries/create">
          <Button className="m-2" variant="success">
            Nueva Entrada
          </Button>
        </Link>
      );
    } else {
      return (
        <Link to="/organisation/create">
          <Button className="btn btn-sm m-2" variant="success">
            Nueva Organización
          </Button>
        </Link>
      );
    }
  };

  return (
    <MainScreen
      title={`Entradas`}
      button={
        userInfo &&
        userInfo.permissions &&
        userInfo.permissions["members"]["create"] &&
        customButton()
      }
    >
      <Row>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        {entries && entries.entries && (
          <div>
            <StripedDataGrid
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              sortModel={[{ field: "entryDate", sort: "desc" }]}
            />
          </div>
        )}
      </Row>
    </MainScreen>
  );
};
