import {
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_FAILURE,
  SUPPLIER_CREATE_REQUEST,
  SUPPLIER_CREATE_SUCCESS,
  SUPPLIER_CREATE_FAILURE,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_FAILURE,
  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAILURE,
} from "../constants/suppliers";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listSuppliers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPLIER_LIST_REQUEST });

    const { data } = await axios.get("/api/suppliers", apiConfig);

    dispatch({ type: SUPPLIER_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPLIER_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const listSupplierById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPLIER_LIST_REQUEST });

    const { data } = await axios.get(`/api/suppliers/${id}`, apiConfig);

    dispatch({ type: SUPPLIER_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPLIER_LIST_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createSupplierAction =
  (name, email, phone, address, city, products, minPurchase, image) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SUPPLIER_CREATE_REQUEST });

      const { data } = await axios.post(
        "/api/suppliers/create",
        {
          name,
          email,
          phone,
          address,
          city,
          products,
          minPurchase,
          image,
        },
        apiConfig
      );

      dispatch({ type: SUPPLIER_CREATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: SUPPLIER_CREATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const updateSupplierAction =
  (id, name, email, phone, address, city, products, minPurchase, image) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SUPPLIER_UPDATE_REQUEST });

      const { data } = await axios.put(
        `/api/suppliers/${id}`,
        {
          name,
          email,
          phone,
          address,
          city,
          products,
          minPurchase,
          image,
        },
        apiConfig
      );

      dispatch({ type: SUPPLIER_UPDATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: SUPPLIER_UPDATE_FAILURE,
        payload: err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const deleteSupplierAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPLIER_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/suppliers/${id}`, apiConfig);

    dispatch({ type: SUPPLIER_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPLIER_DELETE_FAILURE,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
