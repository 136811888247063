import {
  ENTRY_LIST_REQUEST,
  ENTRY_LIST_SUCCESS,
  ENTRY_LIST_FAILURE,
  ENTRY_CREATE_REQUEST,
  ENTRY_CREATE_SUCCESS,
  ENTRY_CREATE_FAILURE,
  ENTRY_UPDATE_REQUEST,
  ENTRY_UPDATE_SUCCESS,
  ENTRY_UPDATE_FAILURE,
  ENTRY_DELETE_REQUEST,
  ENTRY_DELETE_SUCCESS,
  ENTRY_DELETE_FAILURE,
  RESET_ENTRIES,
} from "../constants/entries";

export const entriesReducer = (state = { entries: [] }, action) => {
  switch (action.type) {
    case ENTRY_LIST_REQUEST:
      return { loading: true };
    case ENTRY_LIST_SUCCESS:
      return { loading: false, entries: action.payload };
    case ENTRY_LIST_FAILURE:
      return { loading: false, error: action.payload };
    case ENTRY_CREATE_REQUEST:
      return { loading: true };
    case ENTRY_CREATE_SUCCESS:
      return { loading: false, success: true };
    case ENTRY_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case ENTRY_UPDATE_REQUEST:
      return { loading: true };
    case ENTRY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ENTRY_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case ENTRY_DELETE_REQUEST:
      return { loading: true };
    case ENTRY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ENTRY_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case RESET_ENTRIES:
      return { entries: [] };

    default:
      return state;
  }
};
