import { React, useState, useEffect } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  Card,
  Table,
  Container,
  Modal,
  Alert,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheck, faTimes, faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import MainScreen from "components/layout/mainScreen/mainScreen";
import UserModal from "components/userOrganisation";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import {
  createOrganisationAction,
  updateOrganisationAction,
  createUser,
  deleteUser,
  getOrganisationById,
} from "redux/actions/organisations";
import axios from "axios";
import ErrorMessage from "components/custom/errorMessage";

import "./index.css";

export default () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState(null);
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [editUserPopup, setEditUserPopup] = useState(false);
  const [updateUser, setUpdateUser] = useState(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo, success, error, loading } = organisation;

  const editOrganisationHandler = (e) => {
    dispatch(updateOrganisationAction(organisationInfo._id, name, email, address, phone));
  };

  const resetData = () => {
    setName("");
    setAddress("");
    setPhone("");
    setEmail("");
    setUsers([]);
  };

  const deleteUserHandler = (id) => {
    dispatch(deleteUser(id));
  };

  const fetchData = async () => {
    setUsers(organisationInfo.users);
    setName(organisationInfo.name);
    setAddress(organisationInfo.address);
    setPhone(organisationInfo.phone);
    setEmail(organisationInfo.email);
  };

  useEffect(() => {
    if (!userInfo) window.location.href = "/dashboard";
    else dispatch(getOrganisationById(userInfo.organisation));
    if (success) setMessage("Organización Actualizada con éxto");
  }, [userInfo, success]);

  useEffect(() => {
    if (organisationInfo && organisationInfo.users) fetchData();
  }, [organisationInfo]);

  if (loading) {
    return (
      <MainScreen title="Mi Organización">
        <div className="container mt-5 loginContainer">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Mi Organización">
        <div className="shadow p-3 mb-3 bg-white rounded">
          {/* Datos */}
          <Form>
            <Card className="my-3">
              <Card.Header>Datos</Card.Header>
              <Card.Body>
                <Row>
                  {/* Name */}
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="formBasicUsername">
                      <Form.Label>Nombre Organización</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Introduce Nombre Organización"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                      />
                    </Form.Group>
                  </Col>
                  {/* Email */}
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Introduce Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </Form.Group>
                  </Col>
                  {/* Address */}
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="formBasicAddress">
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control
                        type="address"
                        placeholder="Introduce Dirección"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        required
                      />
                    </Form.Group>
                  </Col>
                  {/* Phone */}
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        type="phone"
                        placeholder="Introduce Teléfono"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* Submit Button */}
                  <Col md="12">
                    <Button
                      variant="primary"
                      type="submit"
                      className=" btn-sm"
                      onClick={(e) => {
                        editOrganisationHandler(e);
                      }}
                    >
                      Guardar Cambios
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>

          {/* Users */}
          <Card className="my-3">
            <Card.Header>Usuarios</Card.Header>
            <Card.Body>
              <Button variant="primary" className="btn-sm" onClick={() => setAddUserPopup(true)}>
                Agregar Usuario
              </Button>
              <Table>
                <thead>
                  <tr>
                    <th>username</th>
                    <th>Email</th>
                    <th>Rol</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user._id}>
                      <td>
                        {user._id === userInfo._id && (
                          <FontAwesomeIcon icon={faUser} color="lightblue" />
                        )}
                        <span className="px-2">{user.username}</span>
                      </td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                      <td>
                        {user.isActive ? (
                          <FontAwesomeIcon icon={faCheck} color="green" />
                        ) : (
                          <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                      </td>

                      <td>
                        <Button
                          variant="warning"
                          className="btn-sm mx-2"
                          onClick={() => {
                            setEditUserPopup(true);
                            setUpdateUser(user);
                          }}
                        >
                          Editar
                        </Button>
                        {user.id === userInfo._id ? (
                          <Button variant="danger" className="btn-sm mx-2" disabled>
                            Eliminar
                          </Button>
                        ) : (
                          <Button
                            variant="danger"
                            className="btn-sm mx-2"
                            onClick={() => deleteUserHandler(user.id)}
                          >
                            Eliminar
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {success && !error && <ErrorMessage variant="success">{success}</ErrorMessage>}

        {/* Modal Add User */}
        {addUserPopup && (
          <UserModal onHide={() => setAddUserPopup(false)} title="Agregar Usuario" type="add" />
        )}
        {editUserPopup && (
          <UserModal
            onHide={() => setEditUserPopup(false)}
            title="Editar Usuario"
            type="update"
            user={updateUser}
          />
        )}
      </MainScreen>
    );
  }
};
