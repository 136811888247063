import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import MainScreen from "../../components/layout/mainScreen/mainScreen";
import Loading from "../../components/custom/loading";
import ErrorMessage from "../../components/custom/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/actions/users";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { loading, error, passwordInfo } = user;

  useEffect(() => {
    if (passwordInfo) {
      window.location.href = "/login";
    }
  }, [passwordInfo]);

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(resetPassword(password, confirmPassword, token));
  };

  if (loading) {
    return (
      <MainScreen title="Reestablecer Contraseña">
        <div className="container mt-5 loginContainer">
          <Loading />
        </div>
      </MainScreen>
    );
  } else if (!loading) {
    return (
      <MainScreen title="Reestablecer Contraseña">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirmar Contraseña"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                required
              />
            </Form.Group>
            {error && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
            <Button variant="primary" className="px-4" type="submit">
              Enviar
            </Button>
          </Form>
        </div>
      </MainScreen>
    );
  }
};

export default ResetPassword;
