import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import placeholder from "../../assets/user_placeholder.png";

/* import css */
import "./index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageInput2, PdfInput } from "components/imageInput";
import MemberSelect from "components/memberSelect";
const openDni = (e, b64) => {
  let pdfWindow = window.open("", "_blank");
  pdfWindow.document.write(`<iframe width='100%' height='100%' src=${encodeURI(b64)}></iframe>`);
};

const toBase64 = (file) => {
  if (!file) return "";

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const UserCard = (props) => {
  const {
    type,
    membershipId,
    nickname,
    referrer,
    referees,
    consumerType,
    firstName,
    lastName,
    email,
    phone,
    address,
    city,
    membershipDate,
    monthlyConsumptionForecast,
    dni,
    image,
    contract,
    dniFront,
    dniBack,
    birthDate,
    gender,
    members,
    setMembershipDate,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setAddress,
    setCity,
    setGender,
    setDni,
    setDniFront,
    setDniBack,
    setBirthDate,
    setMonthlyConsumptionForecast,
    setMembershipId,
    setNickname,
    setReferrer,
    setConsumerType,
    setImage,
    setContract,
  } = props;

  const read = "read";

  return (
    <>
      <Row className="align-items-center m-3">
        <Col md={3}>
          <img
            src={image ? image : placeholder}
            alt="memberImage"
            className="img-fluid rounded-circle shadow memberImage"
            style={{ height: "300px", width: "300px" }}
            onClick={(e) => {
              /* click memberImageInput */
              document.getElementById("memberImageInput").click();
            }}
          />
          <input
            className="d-none"
            type="file"
            id="memberImageInput"
            accept="image/*"
            onChange={async (e) => {
              if (e.target.files[0].size > 5000000) {
                alert("El tamaño de la imagen no puede ser mayor a 5MB");
                return;
              }
              setImage(await toBase64(e.target.files[0]));
            }}
          ></input>
        </Col>
        <Col md={9}>
          <Row>
            <Col md={6}>
              <Table striped="columns">
                <tbody>
                  <tr>
                    {/* Member ID */}
                    <th>ID Socio</th>
                    {type === read && <td className="customValue">{membershipId}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="text"
                          value={membershipId}
                          onChange={(e) => setMembershipId(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    {/* Nickname */}
                    <th>Avalista</th>
                    {type === read && <td className="customValue">{referrer}</td>}
                    {type !== read && members && (
                      <td>
                        <MemberSelect member={referrer} setMember={setReferrer} members={members} />
                      </td>
                    )}
                  </tr>
                  {/* Name */}
                  <tr>
                    <th>Nombre</th>
                    {type === read && <td className="customValue">{firstName}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* Membership Date */}
                  <tr>
                    <th>Fecha de Alta</th>
                    {type === read && <td className="customValue">{membershipDate}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="date"
                          value={membershipDate}
                          onChange={(e) => setMembershipDate(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* DNI */}
                  <tr>
                    <th>DNI</th>
                    {type === read && <td className="customValue">{dni}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="text"
                          value={dni}
                          onChange={(e) => setDni(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* Address */}
                  <tr>
                    <th>Dirección</th>
                    {type === read && <td className="customValue">{address}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* Email */}
                  <tr>
                    <th>Email</th>
                    {type === read && <td className="customValue">{email}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* DNI Front -- pending */}
                  <tr>
                    <th>DNI Front</th>
                    {type === read && (
                      <td>
                        {dniFront ? (
                          <a
                            href={dniFront}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              openDni(e, dniFront);
                            }}
                          >
                            Ver
                          </a>
                        ) : (
                          "No disponible"
                        )}
                      </td>
                    )}
                    {type !== read && (
                      <td>
                        <ImageInput2
                          value={dniFront}
                          setValue={setDniFront}
                          customRef="frontInputId"
                        />
                      </td>
                    )}
                  </tr>
                  {/* Contract */}
                  <tr>
                    <th>Contrato</th>
                    {type === read && (
                      <td>
                        {contract ? (
                          <a
                            href={contract}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              openDni(e, contract);
                            }}
                          >
                            Ver
                          </a>
                        ) : (
                          "No disponible"
                        )}
                      </td>
                    )}
                    {type !== read && (
                      <td>
                        <PdfInput value={contract} setValue={setContract} customRef="contractId" />
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <Table striped="columns">
                <tbody>
                  {/* Monthly Consumption  */}
                  <tr>
                    <th>Apodo</th>
                    {type === read && <td className="customValue">{nickname}</td>}
                    {type !== read && (
                      <td>
                        <input
                          type="text"
                          value={nickname}
                          onChange={(e) => setNickname(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th>Tipo de Consumo</th>
                    {type === read && <td className="customValue">{consumerType}</td>}
                    {type !== read && (
                      <td>
                        <select
                          value={consumerType}
                          onChange={(e) => setConsumerType(e.target.value)}
                          className="customInput"
                        >
                          <option value="">Seleccionar</option>
                          <option value="Recreativo">Recreativo</option>
                          <option value="Terapéutico">Terapéutico</option>
                        </select>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th>Previsión Consumo Anual</th>
                    {type === read && <td className="customValue">{monthlyConsumptionForecast}</td>}
                    {type !== read && (
                      <td>
                        <input
                          type="number"
                          value={monthlyConsumptionForecast}
                          onChange={(e) => setMonthlyConsumptionForecast(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* Lastname */}
                  <tr>
                    <th>Apellido</th>
                    {type === read && <td className="customValue">{lastName}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  <tr>
                    {/* Birthdate */}
                    <th>Fecha de Nacimiento</th>
                    {type === read && <td className="customValue">{birthDate}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="date"
                          value={birthDate}
                          onChange={(e) => setBirthDate(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* Gender */}
                  <tr>
                    <th>Género</th>
                    {type === read && <td className="customValue">{gender}</td>}
                    {type !== read && (
                      <td>
                        <select
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          required
                          style={{
                            fontSize: "1rem",
                            padding: "0.4rem",
                          }}
                          className="customInput"
                        >
                          <option></option>
                          <option value="M">Masculino</option>
                          <option value="F">Femenino</option>
                          <option value="O">Otro</option>
                        </select>
                      </td>
                    )}
                  </tr>
                  {/* City */}
                  <tr>
                    <th>Localidad</th>
                    {type === read && <td className="customValue">{city}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="text"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          style={{ width: "100%" }}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* Phone */}
                  <tr>
                    <th>Teléfono</th>
                    {type === read && <td className="customValue">{phone}</td>}
                    {type !== read && (
                      <td>
                        <input
                          required
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="customInput"
                        />
                      </td>
                    )}
                  </tr>
                  {/* DNI Back -- pending */}
                  <tr>
                    <th>DNI Back</th>
                    {type === read && (
                      <td>
                        {dniBack ? (
                          <a
                            href={dniBack}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              openDni(e, dniBack);
                            }}
                          >
                            Ver
                          </a>
                        ) : (
                          "No disponible"
                        )}
                      </td>
                    )}
                    {type !== read && (
                      <td>
                        <ImageInput2
                          value={dniBack}
                          setValue={setDniBack}
                          customRef="backInputId"
                        />
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-3 mt-5">
        <Col md={12}>
          {/* Referees list */}
          {/* title */}
          <Row>
            <Col md={12}>
              <h5>Socios Avalados</h5>
            </Col>
          </Row>

          <Table striped="columns">
            <thead>
              <tr>
                <th>ID</th>
                <th>Apodo</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Teléfono</th>
                <th>Email</th>
                <th>Tipo Consumo</th>
              </tr>
            </thead>
            <tbody>
              {referees &&
                referees.map((referee, index) => (
                  <tr key={index}>
                    <td>{referee.membershipId}</td>
                    <td>{referee.nickname}</td>
                    <td>{referee.firstName}</td>
                    <td>{referee.lastName}</td>
                    <td>{referee.phone}</td>
                    <td>{referee.email}</td>
                    <td>{referee.consumerType}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default UserCard;
