import { React, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Form, Button, Col, Row } from "react-bootstrap";
import MainScreen from "../../components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/custom/loading";
import ErrorMessage from "../../components/custom/errorMessage";
import { register } from "../../redux/actions/users";
import { permissionsModelFalse, permissionsModelTrue } from "../../utils/permissionsModel";

const RegisterScreen = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState(null);
  const [permissions, setPermissions] = useState(permissionsModelFalse);
  //const [picture, setPicture] = useState("");

  const roleId = searchParams.get("roleId");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { loading, error, userInfo, success } = user;

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(register(username, email, password, confirmPassword, role, permissions));
  };

  useEffect(() => {
    if (userInfo) window.location.href = "/dashboard";
    if (success) setMessage("Porfavor verifique su correo eléctronico");
    //if (success) window.location.href = "/login";

    if (!roleId) setRole("user");
    else if (roleId === "owner") {
      setRole("owner");
      setPermissions(permissionsModelTrue);
    }
  }, [userInfo, success]);

  if (loading) {
    return (
      <MainScreen title="Register">
        <div className="container mt-5 loginContainer">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Register">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formBasicUsername">
              <Form.Label>Nombre de usuario</Form.Label>
              <Form.Control
                type="name"
                placeholder="Introduce Nombre Usuario"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Introduce Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              <Form.Text className="text-muted">No compartiremos su correo con nadie</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirmar Contraseña"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
                required
              />
            </Form.Group>
            {error && !message && <ErrorMessage variant="danger">{error.message}</ErrorMessage>}
            {message && <ErrorMessage variant="success">{message}</ErrorMessage>}
            <Button variant="primary" type="submit">
              Enviar
            </Button>
            <Row className="mt-3">
              <Col>
                <a href="/login">Ya tienes cuenta? ¡Accede!</a>
              </Col>
            </Row>
          </Form>
        </div>
      </MainScreen>
    );
  }
};

export default RegisterScreen;
