import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import MainScreen from "../../components/layout/mainScreen/mainScreen";
import Loading from "../../components/custom/loading";
import ErrorMessage from "../../components/custom/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/actions/users";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { loading, error, passwordInfo } = user;
  useEffect(() => {}, [passwordInfo]);

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(forgotPassword(email));
  };

  if (loading) {
    return (
      <MainScreen title="Reestablecer Contraseña">
        <div className="container mt-5 loginContainer">
          <Loading />
        </div>
      </MainScreen>
    );
  } else if (!loading) {
    return (
      <MainScreen title="Reestablecer Contraseña">
        <div className="container shadow p-3 mb-3 bg-white rounded">
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="emailForm">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Correo Eléctrónico"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>

            {error && !passwordInfo.message && (
              <ErrorMessage variant="danger">{error.message}</ErrorMessage>
            )}
            {passwordInfo && passwordInfo.message && (
              <ErrorMessage variant="success">{passwordInfo.message}</ErrorMessage>
            )}
            <Button variant="primary" className="px-4" type="submit">
              Enviar
            </Button>
          </Form>
        </div>
      </MainScreen>
    );
  }
};

export default ForgotPassword;
