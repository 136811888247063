import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listSuppliers, deleteSupplierAction } from "redux/actions/suppliers";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid } from "components/custom/stripedDataGrid.js";

const currencyFormatter = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

/* Format value grams */
const gramsFormatter = new Intl.NumberFormat("es-ES", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export default () => {
  const dispatch = useDispatch();

  const suppliersReducer = useSelector((state) => state.suppliers);
  const { loading, error, suppliers, success } = suppliersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteSupplierAction(id));
      window.location.href = "/suppliers";
    }
  };

  const defineDataGrid = (suppliers) => {
    let rows = [];

    suppliers.suppliers.forEach((supplier) => {
      rows.push({
        id: supplier._id,
        name: supplier.name,
        email: supplier.email,
        phone: supplier.phone,
        address: supplier.address,
        city: supplier.city,
        minPurchase: currencyFormatter.format(supplier.minPurchase),
        products: supplier.products,
      });
    });

    setRowsDefs(rows);
    setColumnDefs([
      {
        headerName: "Interacciones",
        field: "interactions",
        width: 150,
        renderCell: (rowData) => {
          console.log(rowData);
          return (
            <div>
              {userInfo && userInfo.permissions && userInfo.permissions["purchases"]["create"] && (
                <Link to={`/purchases/create?supplierId=${rowData.row.id}`}>
                  <Button className="btn btn-sm m-2" variant="secondary">
                    Nueva Compra
                  </Button>
                </Link>
              )}
            </div>
          );
        },
      },
      {
        headerName: "ID Proveedor",
        field: "id",
        width: 150,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Nombre",
        field: "name",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Dirección",
        field: "address",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Ciudad",
        field: "city",
        width: 100,

        sortable: true,
        filter: true,
      },
      {
        headerName: "Email",
        field: "email",
        width: 150,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Teléfono",
        field: "phone",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Mínimo de Compra",
        field: "minPurchase",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Productos",
        field: "products",
        width: 100,
        sortable: true,
        filter: true,
        renderCell: (rowData) => {
          return rowData.row.products.length;
        },
      },
      {
        headerName: "Acciones",
        field: "actions",
        width: 200,
        renderCell: (rowData) => {
          return (
            <div>
              <Link to={`/suppliers/${rowData.id}`}>
                <Button className="btn btn-sm m-2" variant="success">
                  Ver
                </Button>
              </Link>
              {userInfo && userInfo.permissions && userInfo.permissions["members"]["update"] && (
                <Link to={`/suppliers/edit/${rowData.id}`}>
                  <Button className="btn btn-sm m-2" variant="warning">
                    Editar
                  </Button>
                </Link>
              )}
              {userInfo && userInfo.permissions && userInfo.permissions["members"]["delete"] && (
                <Button
                  variant="danger"
                  onClick={() => deleteHandler(rowData.id)}
                  className=" btn btn-sm m-2"
                >
                  Eliminar
                </Button>
              )}
            </div>
          );
        },
      },
    ]);
  };

  const customButton = () => {
    if (organisationInfo) {
      return (
        <Link to="/suppliers/create">
          <Button className="m-2" variant="success">
            Nuevo Proveedor
          </Button>
        </Link>
      );
    } else {
      return (
        <Link to="/organisation/create">
          <Button className="btn btn-sm m-2" variant="success">
            Nueva Organización
          </Button>
        </Link>
      );
    }
  };

  useEffect(() => {
    dispatch(listSuppliers());

    if (!userInfo) {
      window.location.href = "/login";
    }
  }, [dispatch, success, userInfo]);

  useEffect(() => {
    if (suppliers && suppliers.suppliers && suppliers.suppliers.length > 0) {
      defineDataGrid(suppliers);
    }
  }, [suppliers]);

  return (
    <MainScreen
      title={`Proveedores`}
      button={
        userInfo &&
        userInfo.permissions &&
        userInfo.permissions["members"]["create"] &&
        customButton()
      }
    >
      <Row>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        {suppliers && suppliers.suppliers && (
          <div>
            <StripedDataGrid
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              sortModel={[{ field: "suppliershipId", sort: "asc" }]}
            />
          </div>
        )}
      </Row>
    </MainScreen>
  );
};
