// import components from react-bootstrap
import { React, useState, useEffect } from "react";

import {
  Container,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
  Image,
} from "react-bootstrap";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSitemap,
  faSignOut,
  faBox,
  faDoorOpen,
  faShop,
  faBoxesPacking,
  faTruck,
  faCartShopping,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import {} from "react-router-dom";
import { logout } from "../../../redux/actions/users";
import mainLogo from "../../../assets/logo.png";

import "./header.css";

const Header = ({ setSearch }) => {
  const [onlyOwner, setOnlyOwner] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [permissions, setPermissions] = useState({});

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  useEffect(() => {
    userInfo && userInfo.role === "owner" ? setOnlyOwner(true) : setOnlyOwner(false);
    organisationInfo && new Date(organisationInfo.subscription) > new Date(Date.now())
      ? setIsSubscribed(true)
      : setIsSubscribed(false);

    userInfo && setPermissions(userInfo.permissions);
  }, [userInfo, organisationInfo]);

  const logoutHandler = () => {
    dispatch(logout());
  };

  const canAccess = (permission) => {
    if (userInfo.role === "owner") return true;
    else
      return permissions && permissions[permission] && permissions[permission].read ? true : false;
  };

  const _class = (path, border) => {
    let stdClassName = "rounded hover border-lightblue customNavLink";
    if (window.location.pathname === path) stdClassName += " customNavlink_active";
    if (border === "start") stdClassName += " border-start";
    if (border === "end") stdClassName += " border-end";
    return stdClassName;
  };

  return (
    <Navbar bg="light" expand="lg" className=" shadow p-3 mb-3 bg-white rounded ">
      <Container fluid className="px-4">
        <Navbar.Brand href="/dashboard" className="">
          <Image
            src={mainLogo}
            width="48"
            height="48"
            className="d-inline-block align-top"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        {userInfo && (
          <>
            <Navbar.Collapse id="navbarScroll">
              {isSubscribed ? (
                <Nav className="me-auto my-2 my-lg-0r" style={{ maxHeight: "100px" }} navbarScroll>
                  {canAccess("members") && (
                    <Nav.Link href="/members" className={_class("/members", "end")}>
                      {" "}
                      <FontAwesomeIcon icon={faUser} className="mx-2" color="lightblue" size="lg" />
                      Socios
                    </Nav.Link>
                  )}
                  {canAccess("products") && (
                    <Nav.Link href="/entries" className={_class("/entries", "end")}>
                      <FontAwesomeIcon
                        icon={faDoorOpen}
                        className="mx-2"
                        color="lightblue"
                        size="lg"
                      />
                      Entradas
                    </Nav.Link>
                  )}
                  {canAccess("products") && (
                    <Nav.Link href="/products" className={_class("/products", "end")}>
                      <FontAwesomeIcon icon={faBox} className="mx-2" color="lightblue" size="lg" />
                      Productos
                    </Nav.Link>
                  )}
                  {canAccess("suppliers") && (
                    <Nav.Link href="/transactions" className={_class("/transactions", "end")}>
                      {" "}
                      <FontAwesomeIcon icon={faShop} className="mx-2" color="lightblue" size="lg" />
                      Transacciones
                    </Nav.Link>
                  )}
                  {canAccess("suppliers") && (
                    <Nav.Link href="/purchases" className={_class("/purchases", "end")}>
                      {" "}
                      <FontAwesomeIcon
                        icon={faBoxesPacking}
                        className="mx-2"
                        color="lightblue"
                        size="lg"
                      />
                      Compras
                    </Nav.Link>
                  )}
                  {canAccess("suppliers") && (
                    <Nav.Link href="/suppliers" className={_class("/suppliers", "end")}>
                      {" "}
                      <FontAwesomeIcon
                        icon={faTruck}
                        className="mx-2"
                        color="lightblue"
                        size="lg"
                      />
                      Proveedores
                    </Nav.Link>
                  )}
                </Nav>
              ) : (
                organisationInfo && (
                  <h5 className="mx-2 my-auto">
                    <NavLink to="/organisation/orders" className="text-decoration-none">
                      Renovar Subscripción
                    </NavLink>
                  </h5>
                )
              )}
              {!organisationInfo && (
                <h5 className="mx-2 my-auto">
                  <NavLink to="/organisation/create" className="text-decoration-none">
                    Crear Organización
                  </NavLink>
                </h5>
              )}
            </Navbar.Collapse>
            {/* Profile */}
            <Nav className="ml-auto" navbarScroll>
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faUser} className="mx-2" color="lightblue" size="lg" />
                    {userInfo.username}
                  </>
                }
                id="basic-nav-dropdown"
                className={_class("/myprofile", "start")}
                style={{
                  marginRight: "7rem",
                }}
              >
                <NavDropdown.Item href="/myprofile">
                  {" "}
                  <FontAwesomeIcon icon={faUser} className="mx-2" color="lightblue" size="lg" />
                  Perfil
                </NavDropdown.Item>
                {canAccess("organisation") && organisationInfo && (
                  <>
                    <NavDropdown.Item href="/myorganisation">
                      <FontAwesomeIcon
                        icon={faSitemap}
                        className="mx-2"
                        color="lightblue"
                        size="lg"
                      />
                      Mi Organización
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/organisation/orders">
                      <FontAwesomeIcon
                        icon={faCartShopping}
                        className="mx-2"
                        color="lightblue"
                        size="lg"
                      />
                      Subscripción y pagos
                    </NavDropdown.Item>
                  </>
                )}
                {onlyOwner && !organisationInfo && (
                  <NavDropdown.Item href="/organisation/create">
                    <FontAwesomeIcon
                      icon={faSitemap}
                      className="mx-2"
                      color="lightblue"
                      size="lg"
                    />
                    Registrar Organización
                  </NavDropdown.Item>
                )}
                <NavDropdown.Divider />

                <NavDropdown.Item
                  href="/"
                  onClick={() => {
                    logoutHandler();
                  }}
                >
                  <FontAwesomeIcon icon={faSignOut} className="mx-2" color="lightblue" size="lg" />
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </>
        )}
        {!userInfo && (
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll>
              <Nav.Link href="/login">Iniciar Sesión</Nav.Link>
              <Nav.Link href="/register?roleId=owner">Registrarse cómo propietario</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
