import { React, useState, useEffect } from "react";
import { Form, Button, Col, Row, Card } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import MainScreen from "components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/custom/loading";
import ErrorMessage from "components/custom/errorMessage";
import { createMemberAction } from "redux/actions/members";
import { getCardHeaderUtilityClass } from "@mui/material";

import { ImageInput } from "components/imageInput/index.js";
import UserCard from "components/userCard";

export default () => {
  const [membershipId, setMembershipId] = useState("");
  const [nickname, setNickname] = useState("");
  const [referrer, setReferrer] = useState("");
  const [consumerType, setConsumerType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [dni, setDni] = useState("");
  const [dniFront, setDniFront] = useState("");
  const [dniBack, setDniBack] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [membershipDate, setMembershipDate] = useState(new Date().toISOString().split("T")[0]);
  const [monthlyConsumptionForecast, setMonthlyConsumptionForecast] = useState(0);
  const [image, setImage] = useState("");
  const [contract, setContract] = useState("");

  const dispatch = useDispatch();

  const membersReducer = useSelector((state) => state.members);
  const { members, loading, error, success } = membersReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const resetHandler = () => {
    setMembershipId("");
    setNickname("");
    setReferrer("");
    setConsumerType("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCity("");
    setGender("");
    setDni("");
    setDniFront("");
    setDniBack("");
    setBirthDate("");
    setMembershipDate("");
    setMonthlyConsumptionForecast(0);
    setImage("");
    setContract("");

    window.location.href = "/members";
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    dispatch(
      createMemberAction(
        membershipId,
        nickname,
        referrer.id,
        consumerType,
        firstName,
        lastName,
        email,
        phone,
        address,
        city,
        gender,
        dni,
        dniFront,
        dniBack,
        birthDate,
        membershipDate,
        monthlyConsumptionForecast,
        image,
        contract
      )
    );
  };

  useEffect(() => {
    if (!userInfo) window.location.href = "/";
    if (success) resetHandler();
  }, [userInfo, success]);

  if (loading) {
    return (
      <MainScreen title="Crear Socio">
        <div className="container-fluid mt-5 createMember">
          <Loading />
        </div>
      </MainScreen>
    );
  } else {
    return (
      <MainScreen title="Crear Socio">
        <form onSubmit={submitHandler} className="p-0">
          <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
            <UserCard
              membershipId={membershipId}
              nickname={nickname}
              referrer={referrer}
              consumerType={consumerType}
              firstName={firstName}
              lastName={lastName}
              email={email}
              phone={phone}
              address={address}
              city={city}
              membershipDate={membershipDate}
              monthlyConsumptionForecast={monthlyConsumptionForecast}
              dniFront={dniFront}
              dniBack={dniBack}
              birthDate={birthDate}
              gender={gender}
              dni={dni}
              image={image}
              contract={contract}
              members={members}
              type="create"
              setMembershipDate={setMembershipDate}
              setMonthlyConsumptionForecast={setMonthlyConsumptionForecast}
              setDniFront={setDniFront}
              setDniBack={setDniBack}
              setBirthDate={setBirthDate}
              setGender={setGender}
              setDni={setDni}
              setMembershipId={setMembershipId}
              setNickname={setNickname}
              setReferrer={setReferrer}
              setConsumerType={setConsumerType}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setPhone={setPhone}
              setAddress={setAddress}
              setCity={setCity}
              setImage={setImage}
              setContract={setContract}
            />
            {/* Update */}
            <Row>
              <Col md={12}>
                <Button
                  variant="primary"
                  type="submit"
                  /* expand button */
                  style={{ width: "100%" }}
                >
                  Crear
                </Button>
              </Col>
            </Row>
          </div>
          {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
          {success && <ErrorMessage variant="success">Member Updated Successfully</ErrorMessage>}
        </form>
      </MainScreen>
    );
  }
};
