export const SUPPLIER_LIST_REQUEST = "SUPPLIER_LIST_REQUEST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_FAILURE = "SUPPLIER_LIST_FAILURE";

export const SUPPLIER_CREATE_REQUEST = "SUPPLIER_CREATE_REQUEST";
export const SUPPLIER_CREATE_SUCCESS = "SUPPLIER_CREATE_SUCCESS";
export const SUPPLIER_CREATE_FAILURE = "SUPPLIER_CREATE_FAILURE";

export const SUPPLIER_UPDATE_REQUEST = "SUPPLIER_UPDATE_REQUEST";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_FAILURE = "SUPPLIER_UPDATE_FAILURE";

export const SUPPLIER_DELETE_REQUEST = "SUPPLIER_DELETE_REQUEST";
export const SUPPLIER_DELETE_SUCCESS = "SUPPLIER_DELETE_SUCCESS";
export const SUPPLIER_DELETE_FAILURE = "SUPPLIER_DELETE_FAILURE";

export const SUPPLIER_RESET = "SUPPLIER_RESET";
