export function addNewProduct(setProductsArray, productsArray) {
  setProductsArray([
    ...productsArray,
    {
      _id: "",
      quantity: "",
      stamps: {
        price: "",
        weight: "",
      },
    },
  ]);
}

export function removeProduct(index, setProductsArray, productsArray) {
  let newArray = [...productsArray];
  newArray.splice(index, 1);
  setProductsArray(newArray);
}

export function updateTotal(productsArray, setTotal) {
  let total = 0;
  productsArray.forEach((product) => {
    total += product.total_cost;
  });
  setTotal(total);
}

export function updateTotalWeight(productsArray, setTotalWeight) {
  let total = 0;
  productsArray.forEach((product) => {
    total += product.total_weight;
  });
  setTotalWeight(total);
}

export function resetHandler(setProductsArray, setTotal, setDate, setTotalWeight) {
  setProductsArray([]);
  setDate("");
  setTotal(0);
  setTotalWeight(0);
  window.location.href = "/purchases";
}
